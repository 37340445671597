import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Navbar from "Components/Molecules/Navbar";
import NavBarMobile from "Components/Molecules/NavBarMobile";
import Footer from "Components/Molecules/Footer";
import ContactCard from "Components/Molecules/ContactCard";
import Header from "./Header";
import Solutions from "./Solutions";
import Quality from "./Quality";
import { Products } from "./Products";
import { Seo } from "Components/Utils/Seo";
import { Cases } from "../SolutionsAdvantages/Cases";
import Brands from "Components/Molecules/Brands";

const CasesWrapper = styled.div`
  margin: 50px 0;

  @media (min-width: 1024px) {
    margin: 100px 0;
  }
`;

const Landing = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    }
  }, []);

  return (
    <>
      <Seo
        title="Ayssa | Inicio"
        description="Ayssa es la empresa líder en aislamientos industriales y servicios derivados"
        keywords="Aislantes Ayssa, Aislantes y Servicios, Aislamientos industriales, Aislamientos térmicos, Aislamientos térmicos industriales, Aislamientos térmicos industriales Guadalajara, Aislantes industriales en Guadalajara, Aislamientos para construcción, Aislantes y Servicios Ayssa, Refrigeración industrial, Tuberías industriales"
        url="https://aislantesyservicios.com/"
      />
      {isMobile ? <NavBarMobile /> : <Navbar />}
      <div id="header">
        <Header />
      </div>
      <Brands />
      <Solutions />
      <Products />
      <Quality />
      <CasesWrapper>
        <Cases />
      </CasesWrapper>
      <ContactCard />
      <Footer />
    </>
  );
};

export default Landing;
