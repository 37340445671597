import React, { useState, useEffect } from "react";
import styled from "styled-components";
import API from "@aws-amplify/api";
import { Link } from "react-router-dom";

import { currentISODate } from "Lib/date";
import { AUTH_MODE } from "Lib/authMode";
import { ArrowForward } from "Icons/ArrowForward";
import Navbar from "Components/Molecules/Navbar";
import NavBarMobile from "Components/Molecules/NavBarMobile";
import { List } from "Components/Views/SolutionsProducts/List";
import { ButtonContainer } from "Components/ButtonContainer";
import { Background } from "Components/Molecules/Background";
import ContactCard from "Components/Molecules/ContactCard";
import Footer from "Components/Molecules/Footer";
import { Polystyrene } from "./Polystyrene";
import { Seo } from "Components/Utils/Seo";

const SortSectionByUserIdAndCreatedAt = `
  query SortSectionByUserIdAndCreatedAt($userId: ID!, $createdAt: String!, $sectionId: ID!) {
    sortSectionByUserIdAndCreatedAt(userId: $userId, createdAt: { le: $createdAt }, filter: { id: { eq: $sectionId } }) {
      items {
        publications(createdAt: { le: $createdAt }, sortDirection: DESC, limit: 50) {
          items {
            id
            title
            image
          }
        }
      }
    }
  }
`;

const Wrapper = styled.div`
  padding-top: 118px;
  background-color: #F8F9FA;  

  @media (min-width: 768px) {
    padding-top: 148px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 1rem;
`;

const ListWrapper = styled.div`
  padding: 50px 0;
  margin: 0 auto;
  max-width: 1086px;
  width: 100%;

  @media (min-width: 768px) {
    padding: 150px 0;
  }
`;

const ButtonWrapper = styled.div`
  padding: 50px 0;
  margin: 0 auto;
  max-width: 1086px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (min-width: 1024px) {
    padding: 100px 0;
  }
`;

const SolutionsProducts = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [
    AislamientoIndustrialContent,
    setAislamientoIndustrialContent,
  ] = useState([]);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    }
  }, []);

  useEffect(() => {
    getAislamientoIndustrialContent();
  }, []);

  async function getAislamientoIndustrialContent() {
    const { data } = await API.graphql({
      query: SortSectionByUserIdAndCreatedAt,
      variables: {
        userId: "3ce07afb-193d-4fb2-97fc-6f3bd87e4a63",
        createdAt: currentISODate,
        sectionId: "8e67760b-8348-457d-b3b9-263739942fa3",
      },
      authMode: AUTH_MODE,
    });

    if (data.sortSectionByUserIdAndCreatedAt.items.length > 0) {
      setAislamientoIndustrialContent(
        data.sortSectionByUserIdAndCreatedAt.items[0].publications.items
      );
    }
  }

  return (
    <>
      <Seo
        title="Ayssa | Productos"
        description="Nuestros servicios, productos y materiales son de gran calidad"
        keywords="Acero en rollo, Aluminio en rollo, Aislamientos industriales, Aislamiento reusable, Poliuretano espreado, Poliuretano inyectado, Poliestireno a medida, Medias cañas de fibra de vidrio, Aislamientos térmicos"
        url="https://aislantesyservicios.com/products"
      />
      {isMobile ? (
        <NavBarMobile subMenuTitle="solutions" />
      ) : (
          <Navbar subMenuTitle="solutions" />
        )}
      <Wrapper id="header">
        <Background
          image="/solutions/PRODUCTOS.png"
          text="Productos de extraordinaria calidad"
        />
        <Container id="content">
          <ListWrapper>
            <List
              title="Aislamiento industrial"
              cards={AislamientoIndustrialContent}
            />
          </ListWrapper>
          <Polystyrene />
          <ButtonWrapper>
            <ButtonContainer>
              <Link to="/services" className="button-container">
                <ArrowForward rotate="180" />
                <button>SERVICIOS</button>
              </Link>
            </ButtonContainer>
            <ButtonContainer>
              <Link to="/advantages" className="button-container">
                <button>VER VENTAJAS</button>
                <ArrowForward />
              </Link>
            </ButtonContainer>
          </ButtonWrapper>
        </Container>
      </Wrapper>
      <ContactCard />
      <Footer />
    </>
  );
};

export default SolutionsProducts;
