import { css } from 'styled-components';

const sizes = {
  desktop: 992,
  tablet: 950,
  phone: 500
}

// Iterate through the sizes and create a media template
const media = (size = null)=>{
  
  const media = Object.keys(sizes).reduce((acc, label) => {
    acc[label] = (...args) => css`
		@media (max-width: ${(size ? size : sizes[label]) / 16}em) {
			${css(...args)}
		}
	`

    return acc
  }, {})

  return media;
}



export default media;