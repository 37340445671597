import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Navbar from "Components/Molecules/Navbar";
import NavBarMobile from "Components/Molecules/NavBarMobile";
import { Background } from "Components/Molecules/Background";
import Footer from "Components/Molecules/Footer";
import ContactCard from "Components/Molecules/ContactCard";
import { ButtonContainer } from "Components/ButtonContainer";
import { Seo } from "Components/Utils/Seo";
import Industries from "./Industries";
import { ArrowForward } from "Icons/ArrowForward";

const Wrapper = styled.div`
  padding-top: 118px;

  @media (min-width: 768px) {
    padding-top: 148px;
  }
`;

const Container = styled.div`
  max-width: 1086px;
  width: 100%;
  margin: 0 auto;
`;

const ButtonWrapper = styled.div`
  padding: 50px 0;

  @media (min-width: 1024px) {
    padding: 100px 0;
  }
`;

const AboutIndustry = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    }
  }, []);

  return (
    <>
      <Seo
        title="Ayssa | Industrias"
        description="40 años de experiencia respaldan nuestro prestigio"
        keywords="Aislantes Ayssa, Aislantes y Servicios, Aislamientos industriales, Aislamientos térmicos, Aislamientos térmicos industriales, Aislamientos térmicos industriales Guadalajara, Aislantes industriales en Guadalajara, Aislamientos para construcción, Aislantes y Servicios Ayssa, Refrigeración industrial, Tuberías industriales"
        url="https://aislantesyservicios.com/industry"
      />
      {isMobile ? (
        <NavBarMobile subMenuTitle="about" />
      ) : (
          <Navbar subMenuTitle="about" />
        )}
      <Wrapper id="header">
        <Background
          image="/nosotros/Banners/INDUSTRIAS.png"
          text="Ayssa es una marca para todas las industrias"
          maxWidthText="1086"
        />
        <Container id="content">
          <Industries />
          <ButtonWrapper>
            <ButtonContainer>
              <Link to="/testimonies" className="button-container">
                <ArrowForward rotate="180" />
                <button>TESTIMONIOS</button>
              </Link>
            </ButtonContainer>
          </ButtonWrapper>
        </Container>
      </Wrapper>
      <ContactCard />
      <Footer />
    </>
  );
};

export default AboutIndustry;
