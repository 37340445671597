import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { ArrowForward } from "Icons/ArrowForward";
import { Arrow } from "Icons/Arrow";
import { Facebook } from "Icons/Facebook";
import { Instagram } from "Icons/Instagram";
import { Linkedin } from "Icons/Linkedin";

const TABLE = [
  {
    title: "Soluciones",
    items: [
      { text: "Productos", link: "/products" },
      { text: "Servicios", link: "/services" },
      { text: "Nuestras ventajas", link: "/advantages" },
    ],
  },
  {
    title: "Nosotros",
    items: [
      { text: "Empresa", link: "/company" },
      { text: "Testimonios", link: "/testimonies" },
      { text: "Industrias", link: "/industry" },
    ],
  },
  {
    title: "Contacto",
    items: [
      { text: "Formulario", link: "/contact" },
      { text: "Oficinas", link: "/contact" },
    ],
  },
];

const Wrapper = styled.div`
  background-color: #212121;
  color: white;
  padding: 50px 20px;

  @media (min-width: 1024px) {
    padding: 0;
    padding-top: 100px;
    padding-bottom: 50px;
  }

  .container {
    max-width: 1086px;
    width: 100%;
    margin: 0 auto;

    .presentation {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (min-width: 1024px) {
        align-items: flex-start;
      }

      img {
        width: 127px;
        height: 47px;
      }

      p {
        font-size: 14px;
        color: #ffffff;
        opacity: 0.7;
        padding-top: 31px;
        margin: 0;
        font-family: Gordita-Regular;

        @media (min-width: 1024px) {
          padding-top: 15px;
        }
      }
    }

    .content {
      @media (min-width: 1024px) {
        display: flex;
        align-items: center;
      }

      .information {
        max-width: 413px;
        width: 100%;
        margin: 60px auto;

        @media (min-width: 1024px) {
          margin: 60px 0;
        }

        p {
          font-family: Gordita-Bold;
          font-size: 18px;
          color: white;
          text-align: center;
          margin: 0;
          padding-bottom: 14px;

          @media (min-width: 1024px) {
            text-align: start;
            font-size: 20px;
          }
        }

        .form {
          display: grid;
          grid-template-columns: minmax(259px, 352px) auto;
          grid-template-rows: repeat(2, auto);

          input {
            border: none;
            height: 52px;
            background-color: white;
            border-radius: 10px;
            padding: 20px 30px;
            font-size: 14px;
            font-family: Gordita-Regular;
          }

          button {
            border: none;
            border-radius: 9999px;
            background-color: white;
            width: 52px;
            height: 52px;
            margin-left: 9px;

            svg {
              width: 24px;
              height: 24px;
              fill: #212121;
            }
          }

          .spam {
            font-size: 18px;
            color: white;
            font-size: 14px;
            opacity: 0.7;
            margin: 0;
            margin-top: 10px;
            justify-self: flex-start;
            font-family: Gordita-Regular;
          }
        }
      }

      .table {
        @media (min-width: 1024px) {
          display: grid;
          grid-template-columns: minmax(auto, 260px) minmax(auto, 260px) auto;
          padding-left: 217px;
        }

        .table-item {
          padding-top: 30px;

          &:first-child {
            padding-top: 0;
          }

          @media (min-width: 1024px) {
            padding: 0;
          }

          .item {
            display: flex;
            justify-content: space-between;
            padding-bottom: 15px;

            @media (min-width: 1024px) {
              display: block;
              padding: 0;
            }

            .text {
              font-size: 14px;
              color: white;
              margin: 0;
            }

            svg {
              height: 20px;
              fill: white;

              @media (min-width: 1024px) {
                display: none;
              }
            }
          }

          hr {
            border: 1px solid white;
            opacity: 0.2;
            margin: 0;

            @media (min-width: 1024px) {
              display: none;
            }
          }

          .list {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
              margin: 16px 0;
            }

            li a {
              font-size: 14px;
              color: white;
              opacity: 0.7;
              font-family: Gordita-Regular;
            }
          }
        }
      }
    }

    .social {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 80px;

      .icons {
        display: flex;

        .linkedin {
          margin-right: 11px;
        }

        .facebook {
          margin-right: 11px;
        }
      }

      .privacy {
        font-size: 14px;
        color: white;
        opacity: 0.7;
        margin: 0;
        font-family: Gordita-Regular;
      }
    }
  }
`;

const Footer = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isExtended, setExtended] = useState(undefined);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    }
  }, []);

  function handleExtended(index) {
    if (isExtended === undefined || isExtended !== index) {
      setExtended(index);
    }

    if (isExtended !== undefined && isExtended === index) {
      setExtended(undefined);
    }
  }

  return (
    <Wrapper>
      <div className="container">
        <div className="presentation">
          <img src="/logo_white.svg" alt="logo" />
          <p>© 2020 Aislantes y servicios S.A. de C.V</p>
        </div>
        <div className="content">
          <div className="information">
            <p>
              Suscríbete y recibe promociones, descuentos y contenido de calidad
            </p>
            <div className="form">
              <input id="email" name="email" placeholder="Email" />
              <button>
                <ArrowForward />
              </button>
              <span className="spam">No enviamos spam.</span>
            </div>
          </div>
          <div className="table">
            {TABLE.map((values, index) => (
              <div className="table-item" key={index}>
                <div className="item" onClick={() => handleExtended(index)}>
                  <p className="text">{values.title}</p>
                  <Arrow width="20" rotate="90" />
                </div>
                <hr />
                <ul className="list">
                  {(!isMobile || isExtended === index) &&
                    values.items.map((item, index) => (
                      <li key={index}>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={item.link}
                        >
                          {item.text}
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
        <div className="social">
          <div className="icons">
            <a
              rel="noopener noreferrer"
              target="_blank"
              className="linkedin"
              href="https://www.linkedin.com/company/ayssamx"
            >
              <Linkedin color="white" />
            </a>
            <a
              rel="noopener noreferrer"
              target="_blank"
              className="facebook"
              href="https://www.facebook.com/ayssamx"
            >
              <Facebook color="white" />
            </a>
            <a
              rel="noopener noreferrer"
              target="_blank"
              className="instagram"
              href="https://www.instagram.com/ayssamx/"
            >
              <Instagram color="white" />
            </a>
          </div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/privacy"
            className="privacy"
          >
            Avisos de privacidad
          </a>
        </div>
      </div>
    </Wrapper>
  );
};

export default Footer;
