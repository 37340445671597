import React, { useState, useEffect } from "react";
import scrollIntoView from "smooth-scroll-into-view-if-needed";
import styled from "styled-components";

import { ArrowForward } from "Icons/ArrowForward";

const Wrapper = styled.div`
  transition: 0.3s;
  opacity: ${(props) => (props.scroll > 500 ? "1" : "0")};
  position: fixed;
  bottom: 0;
  left: 0;
  padding-bottom: 1rem;
  padding-left: 1rem;
  z-index: 10;

  & > button {
    cursor: ${(props) => (props.scroll > 500 ? "pointer" : "auto")};
  }
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  padding: 1rem;
  opacity: 0.75;
  background-color: #212121;
  color: white;
  border-color: transparent;
  border-radius: 999px;
`;

export const UpButton = () => {
  const [scroll, setScroll] = useState(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  function handleScroll() {
    const currentScrollPos = window.pageYOffset;

    setScroll(currentScrollPos);
  }

  function handleGoToTop() {
    const node = document.getElementById("header");

    if (node) {
      scrollIntoView(node, {
        behavior: "smooth",
        block: "start",
      });
    }
  }

  return (
    <Wrapper scroll={scroll}>
      <Button onClick={handleGoToTop}>
        <ArrowForward rotate={270} color="white" />
      </Button>
    </Wrapper>
  );
};
