/**
 * Programadora o Programador del futuro, los paquetes `local_npm` son innecesarios. Si quiere, puede reescribir esta página. Es lo mejor.
 */

import React, { useState, useEffect } from "react";

import { Container } from "local_npm/react-container";
import Typography from "local_npm/react-styled-typography";

import Navbar from "Components/Molecules/Navbar";
import NavBarMobile from "Components/Molecules/NavBarMobile";
import Footer from "Components/Molecules/Footer";
import { Seo } from "Components/Utils/Seo";

const Terms = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    }
  }, []);

  return (
    <>
      {isMobile ? <NavBarMobile /> : <Navbar />}

      <Seo
        title="Ayssa | Privacidad"
        description="Ayssa es la empresa líder en aislamientos industriales y servicios derivados"
        keywords="Aislantes Ayssa, Aislantes y Servicios, Aislamientos industriales, Aislamientos térmicos, Aislamientos térmicos industriales, Aislamientos térmicos industriales Guadalajara, Aislantes industriales en Guadalajara, Aislamientos para construcción, Aislantes y Servicios Ayssa, Refrigeración industrial, Tuberías industriales"
        url="https://aislantesyservicios.com/privacy"
      />

      <Container id="header" width="70%" margin="200px auto">
        <Typography
          color="#212121"
          fontSize="80px"
          weight="500"
          marginB="30"
          tabletCSS="font-size: 55px; line-height:65px;"
        >
          Aviso de privacidad.
        </Typography>

        <Typography fontSize="22px" css="line-height:32px; opacity:0.74;">
          [AISLANTES Y SERVICIOS SA DE CV.] es responsable del tratamiento de
          sus datos personales, con domicilio en [ CIPRES 2130 COLINIA DEL
          FRESNO GUADALAJARA , JAL. CP.44900]
        </Typography>
        <br />
        <br />
        <br />
        <br />
        <Typography fontSize="22px" css="line-height:32px; opacity:0.74;">
          <b>PARA QUÉ RECABAMOS Y UTILIZAMOS SUS DATOS</b>
          <br />
          Los datos que le solicitamos serán utilizados para las siguientes
          finalidades:
          <br />
          <ul>
            <li>OFRECER MAS INFORMACION SOBRE NUESTROS PRDUCTOS Y SERVICIOS</li>
            <li> ACTUALIZACION DE INFORMACION</li>
            <li> PROVEER LOS SERVICIOS Y PRODUCTOS QUE SEAN SOLICITADOS</li>
            <li>
              {" "}
              BRINDAR INFORMACION REQUERIDA ACERCA DE LOS SERVICIOS DE AYSSA.
            </li>
            <li> ENVIO DE INFORMACION QUE SEA SOLICITADA</li>
            <li> BRINDARLE UN MEJOR ATENCION</li>
            <li> NOTIFICAR SOBRE NUEVOS PRODUCTOS O SERVICIOS.</li>
          </ul>
        </Typography>
      </Container>
      <Footer />
    </>
  );
};

export default Terms;
