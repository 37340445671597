import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import { ArrowForward } from "Icons/ArrowForward";

const Wrapper = styled.div`
  ${({ element, index }) => css`
    background-color: ${element === index ? "#377dff" : "white"};
    max-width: 346px;
    width: 100%;
    height: 314px;
    padding: 36px;
    border-radius: 10px;
    position: relative;

    @media (min-width: 768px) {
      height: 400px;
      padding: 43px 36px;
    }

    img {
      width: 47px;
      height: 47px;
    }

    .title {
      color: ${element === index ? "white" : "#212121"};
      font-family: Gordita-Bold;
      font-size: 20px;
      margin: 0;
      padding-top: 18px;
      padding-bottom: 15px;

      @media (min-width: 768px) {
        font-size: 25px;
        padding-top: 33px;
        padding-bottom: 14px;
      }
    }

    .subtitle {
      color: ${element === index ? "white" : "#717371"};
      font-family: Gordita-Regular;
      font-size: 14px;
      margin: 0;
      line-height: 1.5;

      @media (min-width: 768px) {
        font-size: 18px;
      }
    }

    .arrow {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 36px;

      @media (min-width: 768px) {
        bottom: 43px;
      }

      .text {
        color: ${element === index ? "white" : "#717371"};
        font-size: 14px;
        padding-right: 25px;
        margin: 0;
      }

      svg {
        width: 24px;
        height: 24px;
        fill: ${element === index ? "white" : "#717371"};
      }
    }
  `}
`;

export const Card = ({
  index,
  img,
  title,
  subtitle,
  action,
  link
}) => {
  const [hoveredElement, setHoveredElement] = useState(null);

  return (
    <Wrapper
      index={index}
      element={hoveredElement}
      onMouseEnter={() => setHoveredElement(index)}
      onMouseLeave={() => setHoveredElement(null)}
    >
      <img
        src={img}
        alt={title}
      />
      <p className="title">{title}</p>
      <p className="subtitle">{subtitle}</p>
      <Link to={link}>
        <div className="arrow">
          <p className="text">{action}</p>
          <ArrowForward />
        </div>
      </Link>
    </Wrapper>
  )
}
