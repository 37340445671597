import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { ArrowForward } from "Icons/ArrowForward";
import { Facebook } from "Icons/Facebook";
import { Instagram } from "Icons/Instagram";
import { Linkedin } from "Icons/Linkedin";

const ArrowMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 63px;
  padding: 0 20px;

  @media (min-width: 768px) {
    padding: 0 80px;
  }
`;

const Arrow = styled(Link)`
  display: fleX;
`;

const ArrowIcon = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
`;

const ArrowText = styled.p`
  font-size: 14px;
  color: #212121;
  margin-left: 10px;
`;

const SocialNetworks = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 18px;
`;

export const PublicationMenu = ({ link }) => {
  const currentUrl = window.location.href;

  return (
    <ArrowMenu>
      <Arrow to={link}>
        <ArrowIcon>
          <ArrowForward rotate="180" color="#212121" />
        </ArrowIcon>
        <ArrowText>Productos</ArrowText>
      </Arrow>
      <SocialNetworks>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`}
        >
          <Facebook color="#212121" />
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={`https://www.instagram.com/?url=${currentUrl}`}
        >
          <Instagram color="#212121" />
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={`http://www.linkedin.com/shareArticle?mini=true&url=${currentUrl}&summary=Ayssa content!&source=ay`}
        >
          <Linkedin color="#212121" />
        </a>
      </SocialNetworks>
    </ArrowMenu>
  );
};
