import React from "react";

export function ArrowForward({ color, rotate }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      style={{
        transform: `rotate(${rotate ? rotate : 0}deg)`,
      }}
    >
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path
        fill={color}
        d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"
      ></path>
    </svg>
  );
}
