import React from "react";

export function Instagram({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
    >
      <path
        fill={color}
        d="M211.5 209.249a2.249 2.249 0 11-2.251-2.249 2.249 2.249 0 012.251 2.249zm0 0"
        data-name="Path 16952"
        transform="translate(-197.749 -197.749)"
      ></path>
      <path
        fill={color}
        d="M146.276 137.041A2.234 2.234 0 00145 135.76a3.733 3.733 0 00-1.253-.232c-.712-.032-.925-.039-2.726-.039s-2.015.007-2.726.039a3.735 3.735 0 00-1.253.232 2.235 2.235 0 00-1.281 1.281 3.734 3.734 0 00-.232 1.253c-.032.711-.039.925-.039 2.726s.007 2.015.039 2.726a3.733 3.733 0 00.232 1.253 2.234 2.234 0 001.28 1.281 3.729 3.729 0 001.253.232c.711.032.925.039 2.726.039s2.015-.007 2.726-.039a3.729 3.729 0 001.254-.232 2.234 2.234 0 001.276-1.28 3.737 3.737 0 00.232-1.253c.032-.712.039-.925.039-2.726s-.007-2.015-.039-2.726a3.729 3.729 0 00-.232-1.254zm-5.26 7.444a3.465 3.465 0 113.465-3.465 3.465 3.465 0 01-3.465 3.465zm3.6-6.257a.81.81 0 11.81-.81.81.81 0 01-.808.81zm0 0"
        data-name="Path 16953"
        transform="translate(-129.516 -129.52)"
      ></path>
      <path
        fill={color}
        d="M11.5 0A11.5 11.5 0 1023 11.5 11.5 11.5 0 0011.5 0zm6.564 14.222a4.845 4.845 0 01-.307 1.6 3.377 3.377 0 01-1.931 1.931 4.848 4.848 0 01-1.6.307c-.7.032-.929.04-2.723.04s-2.018-.008-2.723-.04a4.848 4.848 0 01-1.6-.307 3.376 3.376 0 01-1.931-1.931 4.844 4.844 0 01-.307-1.6c-.032-.7-.04-.929-.04-2.723s.008-2.018.04-2.723a4.845 4.845 0 01.307-1.6 3.379 3.379 0 011.925-1.933 4.849 4.849 0 011.6-.307c.7-.032.929-.04 2.723-.04s2.018.008 2.723.04a4.85 4.85 0 011.6.307 3.377 3.377 0 011.931 1.931 4.844 4.844 0 01.307 1.6c.032.7.04.929.04 2.723s.002 2.021-.034 2.725zm0 0"
        data-name="Path 16954"
      ></path>
    </svg>
  );
}
