import { useState, useEffect } from "react";

export function useVisibility() {
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    if (!window) return;

    function handleScroll() {
      const currentScrollPos = Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
      );
      const isVisible = currentScrollPos > 300;
    
      setVisible(isVisible);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isVisible]);

  return {
    isVisible
  };
}
