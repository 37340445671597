import React, { useState } from "react";

export const Context = React.createContext();

export const Provider = ({ children }) => {
  const [checkedElement, setCheckedElement] = useState("Todas");

  function handleCheckedMenu(element) {
    setCheckedElement(element);
  }

  return (
    <Context.Provider
      value={{
        checkedElement,
        onCheckedMenu: (element) => handleCheckedMenu(element),
      }}
    >
      {children}
    </Context.Provider>
  );
};
