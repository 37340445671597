import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

import Navbar from "Components/Molecules/Navbar";
import NavBarMobile from "Components/Molecules/NavBarMobile";
import { ButtonContainer } from "Components/ButtonContainer";
import ContactCard from "Components/Molecules/ContactCard";
import Footer from "Components/Molecules/Footer";
import { ArrowForward } from "Icons/ArrowForward";
import { Seo } from "Components/Utils/Seo";
import { Background } from "Components/Molecules/Background";

const Wrapper = styled.div`
  padding-top: 118px;
  background-color: #F8F9FA;

  @media (min-width: 768px) {
    padding-top: 148px;
  }
`;

const Title = styled.h2`
  font-size: 25px;
  font-family: Gordita-Bold;
  color: #212121;
  width: 240px;
  margin: 0 auto;
  text-align: center;
  line-height: 1.5;
  padding-top: 50px;
  padding-bottom: 15px;

  @media (min-width: 768px) {
    font-size: 60px;
    width: 532px;
    line-height: 1.2;
    padding-top: 150px;
    padding-bottom: 21px;
  }
`;

const Text = styled.p`
  color: #717371;
  font-size: 14px;
  width: 321px;
  margin: 0 auto;
  text-align: center;
  line-height: 1.5;
  padding-bottom: 35px;
  font-family: Gordita-Regular;

  @media (min-width: 768px) {
    font-size: 20px;
    width: 661px;
    padding-bottom: 75px;
  }
`;

const Cards = styled.div`
  display: grid;
  grid-gap: 1rem;
  padding: 0 1rem;
`;

const Card = styled.div`
  background-color: white;
  margin: 0 auto;
  border-radius: 10px;
  max-width: 1086px;
  width: 100%;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: ${({ isReverse }) => isReverse ? "row-reverse" : "row"};
  }

  .image {
    background-image: url('${({ image }) => image}');
    background-size: cover;
    background-position: center;
    max-width: 543px;
    width: 100%;
    height: 286px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;

    @media (min-width: 768px) {
      height: 600px;
      border-top-right-radius: ${({ isReverse }) => isReverse ? "10px" : "0px"};
      border-bottom-right-radius: ${({ isReverse }) => isReverse ? "10px" : "0px"};
      border-bottom-left-radius: ${({ isReverse }) => isReverse ? "0px" : "10px"};
      border-top-left-radius: ${({ isReverse }) => isReverse ? "0px" : "10px"};
    }
  }

  .content {
    max-width: 397px;
    width: 100%;
    margin: 0 auto;
    padding: 50px 37px;

    @media (min-width: 768px) {
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .title {
      color: #212121;
      font-size: 20px;
      font-family: Gordita-Bold;
      margin: 0;

      @media (min-width: 768px) {
        font-size: 40px;
      }
    }

    .description {
      color: #717371;
      font-size: 14px;
      margin: 0;
      line-height: 1.5;
      font-family: Gordita-Regular;
      padding-top: 15px;

      @media (min-width: 768px) {
        font-size: 20px;
      }
    }
  }
`;

const ButtonWrapper = styled.div`
  padding: 50px 0;
  margin: 0 auto;
  max-width: 1086px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (min-width: 1024px) {
    padding: 100px 0;
  }
`;

const SolutionsServices = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    }
  }, []);

  return (
    <>
      <Seo
        title="Ayssa | Servicios"
        description="Nuestros servicios, productos y materiales son de gran calidad"
        keywords="Acero en rollo, Aluminio en rollo, Aislamientos industriales, Aislamiento reusable, Poliuretano espreado, Poliuretano inyectado, Poliestireno a medida, Medias cañas de fibra de vidrio, Aislamientos térmicos"
        url="https://aislantesyservicios.com/services"
      />
      {isMobile ? (
        <NavBarMobile subMenuTitle="solutions" />
      ) : (
          <Navbar subMenuTitle="solutions" />
        )}
      <Wrapper id="header">
        <Background
          image="/solutions/SERVICIOS.png"
          text="Nuestros servicios"
        />
        <div id="content">
          <Fade>
            <Title>Nuestros servicios a detalle</Title>
          </Fade>
          <Text>
            Nuestras soluciones para la industria a través de un equipo especializado, utilizando siempre productos de la más alta calidad
          </Text>
          <Cards>
            <Card image="/home/Aislamiento_Termico.png">
              <div
                className="image"
              />
              <div className="content">
                <p className="title">Aislamiento térmico</p>
                <p className="description">
                  Ayssa ofrece soluciones de clase mundial para controlar temperaturas en
                  procesos industriales, ayudando en el ahorro del consumo energético y en los
                  indicadores de sustentabilidad.
                  <br />
                  <br />
                  Todos los productos utilizados son de alta calidad y aptos para cualquier
                  industria. Gracias a la experiencia de nuestro equipo, podemos garantizar
                  tiempos de entrega, atender imprevistos y resolver proyectos complejos.
                </p>
              </div>
            </Card>
            <Card
              image="/home/Mantenimiento.png"
              isReverse={true}
            >
              <div
                className="image"
              />
              <div className="content">
                <p className="title">Mantenimiento</p>
                <p className="description">
                  Ofrecemos servicios de asesoría, ejecución de obra y mantenimiento.
                  Nuestro equipo certificado y altamente calificado está a la altura de los mejores
                  estándares de calidad y seguridad para garantizar resultados.
                </p>
              </div>
            </Card>
            <Card image="/solutions/POLIESTIRENO.png">
              <div
                className="image"
              />
              <div className="content">
                <p className="title">Fabricación de poliestireno</p>
                <p className="description">
                  Comprometidos con los tiempos de entrega y la calidad que nuestros clientes
                  esperan, nuestro personal y maquinaria especializada nos permite entregar el
                  poliestireno para aislamientos térmicos y acústicos en forma de placas, bloques,
                  cubos y medias cañas para tuberías.
                </p>
              </div>
            </Card>
          </Cards>
          <ButtonWrapper>
            <ButtonContainer>
              <Link
                to="/products"
                className="button-container"
              >
                <button>VER PRODUCTOS</button>
                <ArrowForward />
              </Link>
            </ButtonContainer>
          </ButtonWrapper>
        </div>
      </Wrapper>
      <ContactCard />
      <Footer />
    </>
  );
};

export default SolutionsServices;
