import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  max-height: 167px;
  max-width: 272px;
  width: 100%;

  img {
    width: 47px;
    height: 47px;
  }

  .card-title {
    font-size: 18px;
    color: #212121;
    font-family: Gordita-Bold;
    margin: 15px 0;

    @media (min-width: 1024px) {
      font-size: 20px;
    }
  }

  .card-subtitle {
    font-size: 14px;
    color: #757575;
    margin: 0;
    font-family: Gordita-Regular;
    line-height: 1.5;
  }
`;

export const Card = ({ img, title, subtitle }) => {
  return (
    <Wrapper>
      <img
        src={img}
        alt={title}
      />
      <p className="card-title">{title}</p>
      <p className="card-subtitle">{subtitle}</p>
    </Wrapper>
  );
}
