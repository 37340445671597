import React, { useState, useEffect } from "react";
import styled from "styled-components";
import API from "@aws-amplify/api";
import Fade from "react-reveal/Fade";

import { Card } from "Components/Views/Cases/Card";
import { NextButton } from "../../Molecules/NextButton";
import { SortSectionByUserIdAndCreatedAt } from "../Cases/queries";
import { currentISODate } from "Lib/date";
import { AUTH_MODE } from "Lib/authMode";

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 0 1rem;
  max-width: 1086px;
  width: 100%;
  display: grid;
  grid-template-areas:
    "title"
    "content"
    "button";

  @media (min-width: 1024px) {
    padding: 0;
    grid-template-areas:
      "title button"
      "content content";
  }
  
  .button {
    margin: 0;
    margin-top: 50px;
    grid-area: button;
    justify-self: center;

    @media (min-width: 1024px) {
      margin-top: 52.5px;
      margin-bottom: 94.5px;
      justify-self: end;
    }
  }
`;

const Title = styled.h2`
  color: #212121;
  font-size: 25px;
  margin: 0;
  margin-bottom: 35px;
  grid-area: title;
  width: 320px;
  font-family: Gordita-Bold;

  @media (min-width: 1024px) {
    width: 689px;
    font-size: 50px;
    margin-bottom: 75px;
  }
`;

const Cards = styled.div`
  grid-area: content;
  display: grid;
  grid-gap: 10px;

  @media (min-width: 1280px) {
    grid-gap: 20px;
  }
`;

export const Cases = () => {
  const [cases, setCases] = useState([]);

  useEffect(() => {
    getCases();
  }, []);

  async function getCases() {
    const { data } = await API.graphql({
      query: SortSectionByUserIdAndCreatedAt,
      variables: {
        userId: "3ce07afb-193d-4fb2-97fc-6f3bd87e4a63",
        createdAt: currentISODate,
        sectionId: "b86715a7-78f1-411f-907f-f701d3756db0",
        limit: 2,
      },
      authMode: AUTH_MODE,
    });

    if (data.sortSectionByUserIdAndCreatedAt.items.length > 0) {
      setCases(
        data.sortSectionByUserIdAndCreatedAt.items[0].publications.items
      );
    }
  }

  return (
    <Wrapper>
      <Fade bottom>
        <Title>
          Nuestro trabajo transforma proyectos en casos de éxito
        </Title>
      </Fade>
      <Fade>
        <div className="button">
          <NextButton
            toRoute="/projects"
            text="VER PROYECTOS"
          />
        </div>
      </Fade>
      <Cards>
        {cases.map((item) => (
          <Card
            key={item.id}
            id={item.id}
            title={item.title}
            image={item.image}
          />
        ))}
      </Cards>
    </Wrapper>
  );
};
