import React, { useState, useEffect } from "react";
import styled from "styled-components";
import API from "@aws-amplify/api";

import { Card } from "../Cases/Card";
import { currentISODate } from "Lib/date";
import { AUTH_MODE } from "Lib/authMode";

const SortSectionByUserIdAndCreatedAt = `
  query SortSectionByUserIdAndCreatedAt($userId: ID!, $createdAt: String!, $sectionId: ID!, $limit: Int) {
    sortSectionByUserIdAndCreatedAt(userId: $userId, createdAt: { le: $createdAt }, filter: { id: { eq: $sectionId } }) {
      items {
        publications(createdAt: { le: $createdAt }, sortDirection: DESC, limit: $limit) {
          items {
            id
            title
            image
          }
        }
      }
    }
  }
`;

const Container = styled.div`
  background-color: #f8f9fa;
`;

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1086px;
  padding: 50px 20px;

  @media (min-width: 768px) {
    padding: 0;
    padding-top: 150px;
    padding-bottom: 131px;
  }
`;

const Title = styled.div`
  font-family: Gordita-Bold;
  font-size: 20px;
  color: #212121;
  padding-bottom: 50px;

  @media (min-width: 768px) {
    font-size: 30px;
    padding-bottom: 75px;
  }
`;

const Cards = styled.div`
  display: grid;
  grid-gap: 10px;

  @media (min-width: 768px) {
    grid-gap: 20px;
  }
`;

export const Explore = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const { data } = await API.graphql({
      query: SortSectionByUserIdAndCreatedAt,
      variables: {
        userId: "3ce07afb-193d-4fb2-97fc-6f3bd87e4a63",
        createdAt: currentISODate,
        sectionId: "b86715a7-78f1-411f-907f-f701d3756db0",
        limit: 2,
      },
      authMode: AUTH_MODE,
    });

    if (data.sortSectionByUserIdAndCreatedAt.items.length > 0) {
      setData(data.sortSectionByUserIdAndCreatedAt.items[0].publications.items);
    }
  }

  return (
    <Container>
      <Wrapper>
        <Title>Explora otros casos de estudio</Title>
        <Cards>
          {data.map((item, index) => (
            <Card
              key={index}
              id={item.id}
              title={item.title}
              image={item.image}
            />
          ))}
        </Cards>
      </Wrapper>
    </Container>
  );
};
