export const SortSectionByUserIdAndCreatedAt = `
  query SortSectionByUserIdAndCreatedAt($userId: ID!, $createdAt: String!, $sectionId: ID!, $limit: Int = 50) {
    sortSectionByUserIdAndCreatedAt(userId: $userId, createdAt: { le: $createdAt }, filter: { id: { eq: $sectionId } }) {
      items {
        publications(createdAt: { le: $createdAt }, sortDirection: DESC, limit: $limit) {
          items {
            id
            title
            image
          }
        }
      }
    }
  }
`;

export const SortSectionByUserIdAndCreatedAtByTag = `
  query SortSectionByUserIdAndCreatedAt($userId: ID!, $createdAt: String!, $sectionId: ID!, $tag: String, $limit: Int = 50) {
    sortSectionByUserIdAndCreatedAt(userId: $userId, createdAt: { le: $createdAt }, filter: { id: { eq: $sectionId } }) {
      items {
        publications(createdAt: { le: $createdAt }, filter: { tag: { eq: $tag } }, sortDirection: DESC, limit: $limit) {
          items {
            id
            title
            image
          }
        }
      }
    }
  }
`;
