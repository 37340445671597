import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 124px;
  height: 35px;
  background-color: #f8f9fa;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4rem 0;

  @media (min-width: 768px) {
    width: 160px;
    height: 44px;
  }
`;

const Text = styled.p`
  color: #212121;
  font-size: 14px;
  font-weight: 500;
`;

export const Tag = ({ text }) => {
  return (
    <Wrapper>
      <Text>{text}</Text>
    </Wrapper>
  );
};
