import React from "react";
import styled from "styled-components";

import { Card } from "./Card";

const INDUSTRIES = [
  {
    img: '/nosotros/INDUSTRIAS/ALIMENTOS.svg',
    title: 'Alimentos',
    subtitle: 'Empresas globales de productos de consumo.',
    action: 'EMPRESA'
  },
  {
    img: '/nosotros/INDUSTRIAS/BEBIDAS.svg',
    title: 'Bebidas',
    subtitle:
      'Servicios y asesoría para refresqueras, cerveceras, tequileras, etc.',
    action: 'NUESTROS CLIENTES'
  },
  {
    img: '/nosotros/INDUSTRIAS/QUIMICA-FARMA.svg',
    title: 'Química-Farma',
    subtitle:
      'Ayssa trabaja en entornos complejos y con altos estándares de calidad.',
    action: 'INDUSTRIAS'
  },
  {
    img: '/nosotros/INDUSTRIAS/CONSTRUCCIÓN.svg',
    title: 'Construcción',
    subtitle:
      'Para proyectos y obras en donde los materiales de calidad y tiempo de entrega son claves.',
    action: 'EMPRESA'
  },
  {
    img: '/nosotros/INDUSTRIAS/AGRICULTURA.svg',
    title: 'Agricultura',
    subtitle: 'Presentes en una de las industrias más importantes del país.',
    action: 'NUESTROS CLIENTES'
  },
  {
    img: '/nosotros/INDUSTRIAS/MECANICA.svg',
    title: 'Metal-Mecánica',
    subtitle:
      'La alta especialidad está presente en nuestros equipos de trabajo.',
    action: 'INDUSTRIAS'
  },
  {
    img: '/nosotros/INDUSTRIAS/HOTELERIA-TURISMO.svg',
    title: 'Hotelería - Turismo',
    subtitle:
      'PEntornos en donde el clima y el medio ambiente son elementos fundamentales para dar resultados.',
    action: 'EMPRESA'
  },
  {
    img: '/nosotros/INDUSTRIAS/MADERA-CELULOSA.svg',
    title: 'Madera-Celulosa',
    subtitle:
      'PEn la industria de la madera y los productos de su transformación.',
    action: 'NUESTROS CLIENTES'
  },
  {
    img: '/nosotros/INDUSTRIAS/TECNOLOGIA.svg',
    title: 'Tecnología',
    subtitle:
      'Hemos apoyado a la industria tecnológica y sistemas de alta especialización.',
    action: 'INDUSTRIAS'
  },
  {
    img: '/nosotros/INDUSTRIAS/TRANSPORTE.svg',
    title: 'Transporte terrestre y marítimo',
    subtitle:
      'La marca Ayssa es garantía inclusive en industrias complejas como la aeronáutica y transportación.',
    action: 'INDUSTRIAS'
  }
];

const Wrapper = styled.div`
  display: grid;
  justify-content: center;
  grid-gap: 50px;
  padding-top: 50px;

  @media (min-width: 1024px) {
    padding-top: 150px;
    grid-template-columns: repeat(2, auto);
    grid-row-gap: 70px;
    grid-column-gap: 142px;
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(3, auto);
  }
`;

const Industries = () => (
  <Wrapper>
    {INDUSTRIES.map((item, key) => (
      <Card
        id={key}
        key={key}
        {...item}
      />
    ))}
  </Wrapper>
);

export default Industries;
