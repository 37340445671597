import React, { useState, useEffect } from "react";
import API from "@aws-amplify/api";
import styled from "styled-components";
import Fade from "react-reveal/Fade";

import { List } from "Components/Views/SolutionsProducts/List";
import { Polystyrene } from "Components/Views/SolutionsProducts/Polystyrene";
import { currentISODate } from "Lib/date";
import { AUTH_MODE } from "Lib/authMode";

const SortSectionByUserIdAndCreatedAt = `
  query SortSectionByUserIdAndCreatedAt($userId: ID!, $createdAt: String!, $sectionId: ID!, $limit: Int) {
    sortSectionByUserIdAndCreatedAt(userId: $userId, createdAt: { le: $createdAt }, filter: { id: { eq: $sectionId } }, limit: $limit) {
      items {
        publications(createdAt: { le: $createdAt }, sortDirection: DESC, limit: $limit) {
          items {
            id
            title
            image
          }
        }
      }
    }
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 1rem;
`;

const Title = styled.h2`
  color: #212121;
  font-size: 25px;
  width: 310px;
  margin: 50px 0;
  text-align: center;
  font-family: Gordita-Bold;

  @media (min-width: 1024px) {
    width: 720px;
    font-size: 60px;
    margin: 100px 0;
  }
`;

const ListWrapper = styled.div`
  width: 100%;
  max-width: 1086px;
  margin: 50px auto;

  @media (min-width: 1024px) {
    margin: 100px auto;
  }
`;

export const Products = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const { data } = await API.graphql({
      query: SortSectionByUserIdAndCreatedAt,
      variables: {
        userId: "3ce07afb-193d-4fb2-97fc-6f3bd87e4a63",
        createdAt: currentISODate,
        sectionId: "8e67760b-8348-457d-b3b9-263739942fa3",
        limit: 4,
      },
      authMode: AUTH_MODE,
    });

    if (data.sortSectionByUserIdAndCreatedAt.items.length > 0) {
      setData(data.sortSectionByUserIdAndCreatedAt.items[0].publications.items);
    }
  }

  return (
    <Container>
      <Fade bottom>
        <Title>A tu alcance, productos de extraordinaria calidad</Title>
      </Fade>
      <Polystyrene />
      <ListWrapper>
        <List
          title="Productos para nuestros servicios"
          cards={data}
          isList={true}
        />
      </ListWrapper>
    </Container>
  );
};
