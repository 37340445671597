import React from "react";
import styled from "styled-components";
import Flickity from "react-flickity-component";
import BRANDS from "Components/Constants/Brands";

const Container = styled.div`
  margin-left: 2rem;
  margin-right: 1rem;

  @media (min-width: 768px) {
    margin-left: 3rem;
    margin-right: 2rem;
  }
`;

const Image = styled.img`
  width: 70px;
  height: 28px;
  margin: 1rem;

  @media (min-width: 768px) {
    width: 128px;
    height: 50px;
    margin: 2rem;
  }
`;

const Brands = () => {
  return (
    <Container>
      <Flickity
        static={true}
        options={{
          prevNextButtons: false,
          autoPlay: 1600,
          pageDots: false,
          contain: true,
        }}
      >
        {BRANDS.map((brand, key) => (
          <Image key={key} src={brand.img} />
        ))}
      </Flickity>
    </Container>
  );
};

export default Brands;
