import escapeHtml from "escape-html";
import { Text } from "slate";

export const Serialize = (node) => {
  if (Text.isText(node)) {
    const text = escapeHtml(node.text);

    if (Text.matches(node, { bold: true })) {
      return `<b>${text}</b>`;
    }

    if (Text.matches(node, { italic: true })) {
      return `<i>${text}</i>`;
    }

    if (Text.matches(node, { underline: true })) {
      return `<p style="text-decoration: underline;">${text}</p>`;
    }

    return `<span>${text}</span>`;
  }

  const children = node.children.map((n) => Serialize(n)).join("");

  switch (node.type) {
    case "paragraph":
      return `<p class="shooter-description">${children}</p>`;
    case "image":
      return `<img class="shooter-image" src="${node.image}" />`;
    case "heading-one":
      return `<h1 class="shooter-title">${children}</h1>`;
    case "heading-two":
      return `<h2 class="shooter-subtitle">${children}</h2>`;
    case "block-quote":
      return `<blockquote><p>${children}</p></blockquote>`;
    case "numbered-list":
      return `<ol class="shooter-lo-list">${children}</ol>`;
    case "bulleted-list":
      return `<ul class="shooter-ul-list">${children}</ul>`;
    case "list-item":
      return `<li>${children}</li>`;
    case "link":
      return `<a target="_blank" rel="noopener noreferrer" href="${escapeHtml(
        node.url
      )}">${children}</a>`;
    default:
      return children;
  }
};
