import React from "react";
import ReactDOM from "react-dom";
import Amplify from "@aws-amplify/core";
import { ThemeProvider } from "styled-components";
import { BrowserRouter } from "react-router-dom";

import "flickity/dist/flickity.min.css";

import App from "./App";

import awsconfig from "./aws-exports";

Amplify.configure(awsconfig);

const Init = () => {
  return (
    <ThemeProvider theme={{}}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  );
};

ReactDOM.render(<Init />, document.getElementById("root"));
