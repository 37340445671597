import React from "react";
import { Helmet } from "react-helmet";

export const Seo = ({
  title = "Ayssa - Aislantes y Servicios",
  image = "/cover.jpg",
  description,
  keywords,
  url,
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta property="og:type" content="website" />
      <meta name="og:title" property="og:title" content={title} />
      <meta name="author" content="Ayssa Aislantes y Servicios" />
      <meta
        name="og:description"
        property="og:description"
        content={description}
      />
      <meta property="og:image" content={image} />
      <meta property="og:site_name" content="Change" />
      <meta property="og:url" content={url} />
      <link rel="shortcut icon" href="/logo.svg" />
      <link rel="apple-touch-icon" href="/logo.svg" />
      <link rel="canonical" href="https://aislantesyservicios.com/" />
    </Helmet>
  );
};
