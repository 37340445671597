import React, { useContext } from "react";
import styled from "styled-components";
import { Close } from "Icons/Close";
import { Context as IndustryMenuContext } from "Context/IndustryMenuContext";

const ELEMENT = [
  "Todas",
  "Alimentos",
  "Bebidas",
  "Química - Farma",
  "Construcción",
  "Agricultura",
  "Metal - Mecánica",
  "Hotelería - Turismo",
  "Madera - Celulosa",
  "Tecnología",
  "Transporte terrestre y marítimo",
];

const Container = styled.div`
  display: ${(props) => (props.isOpen ? "block" : "none")};

  @media (min-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    background-color: rgba(33, 33, 33, 0.5);
  }
`;

const Wrapper = styled.div`
  /* Parent menu has "position: fixed" to avoid it, this menu has this position as well, to ignore the properties from the parent */
  top: 124px;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;

  @media (min-width: 768px) {
    top: 0;
    right: 0;
    left: auto;
  }
`;

const MenuTitle = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 2rem;
    background-color: white;
  }
`;

const MenuTitleText = styled.p`
  font-size: 20px;
  font-weight: 500;
`;

const WrapperClose = styled.span`
  cursor: pointer;
`;

const Menu = styled.div`
  background-color: #fafafa;
  padding: 2rem 1rem;

  @media (min-width: 768px) {
    height: 100vh;
    max-width: 410px;
    width: 100%;
    background-color: white;
    padding: 3rem 2rem;
  }
`;

const Line = styled.hr`
  display: none;

  @media (min-width: 768px) {
    display: block;
    margin: 0;
    border: 0.5px solid #dadce0;
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 2rem;
  margin-bottom: 2rem;
  cursor: pointer;
  user-select: none;
  color: ${(props) =>
    props.element === props.checkedElement ? "#377DFF" : "#717371"};

  /* Hide the browser's default checkbox */
  & > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
`;

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 17px;
  width: 17px;
  border: 2px solid;
  border-color: ${(props) =>
    props.element === props.checkedElement ? "#212121" : "#717371"};
  border-radius: 999px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ActiveCheckmark = styled.div`
  width: 7px;
  height: 7px;
  background-color: #212121;
  border-radius: 999px;
`;

export const IndustryMenu = ({ isOpen, onMenuOpen }) => {
  const { checkedElement, onCheckedMenu } = useContext(IndustryMenuContext);

  function handleCheckedMenu(element) {
    onCheckedMenu(element);
    onMenuOpen();
  }

  return (
    <Container isOpen={isOpen}>
      <Wrapper>
        <MenuTitle>
          <MenuTitleText>Ver por industrias</MenuTitleText>
          <WrapperClose onClick={onMenuOpen}>
            <Close />
          </WrapperClose>
        </MenuTitle>
        <Line />
        <Menu>
          {ELEMENT.map((element, index) => (
            <Label
              key={index}
              element={element}
              checkedElement={checkedElement}
              onClick={() => handleCheckedMenu(element)}
            >
              <Checkmark element={element} checkedElement={checkedElement}>
                {checkedElement === element && <ActiveCheckmark />}
              </Checkmark>
              {element}
            </Label>
          ))}
        </Menu>
      </Wrapper>
    </Container>
  );
};
