import React from "react";
import Flickity from "react-flickity-component";
import styled from "styled-components";
import Fade from "react-reveal/Fade";

const Container = styled.div`
  .flickity-page-dots {
    bottom: 10px;
  }

  .flickity-page-dots > .dot {
    background: white;
  }
`;

const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("${(props) => props.image}");
  background-position: top;
  background-size: cover;
  width: 100%;
  height: 80vh;
`;

const Text = styled.p`
  font-size: 30px;
  font-family: Gordita-Bold;
  color: #ffffff;
  font-weight: 500;

  @media (min-width: 768px) {
    font-size: 80px;
  }
`;

const Landing = () => {
  return (
    <Container>
      <Flickity
        static={true}
        options={{ prevNextButtons: false, autoPlay: true }}
      >
        <Background
          image={"/home/Innovacion.png"}
        >
          <Fade>
            <Text>Ayssa es Innovación</Text>
          </Fade>
        </Background>
        <Background
          image={"/home/Experiencia.png"}
        >
          <Text>Ayssa es Experiencia</Text>
        </Background>
        <Background
          image={"/home/Calidad.png"}
        >
          <Text>Ayssa es Calidad</Text>
        </Background>
        <Background
          image={"/home/Cercania.png"}
        >
          <Text>Ayssa es Cercanía</Text>
        </Background>
      </Flickity>
    </Container>
  );
};

export default Landing;
