import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import Navbar from "Components/Molecules/Navbar";
import NavBarMobile from "Components/Molecules/NavBarMobile";
import Footer from "Components/Molecules/Footer";
import { Seo } from "Components/Utils/Seo";
import { Room } from "Icons/Room";
import { Linkedin } from "Icons/Linkedin";
import { Facebook } from "Icons/Facebook";
import { Instagram } from "Icons/Instagram";
import { SettingsPhone } from "Icons/SettingsPhone";
import { ArrowForward } from "Icons/ArrowForward";

const Wrapper = styled.div`
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  padding-top: 60px;

  @media (min-width: 768px) {
    padding: 0;
    padding-top: 85px;
  }

  @media (min-width: 1280px) {
    max-width: 1086px;
    display: grid;
    grid-template-areas:
      "title contact"
      "image contact"
      "form form";
  }
`;

const Title = styled.div`
  padding-top: 50px;
  padding-bottom: 25px;
  font-family: Gordita-Bold;
  color: #212121;
  font-size: 30px;

  @media (min-width: 1280px) {
    padding-top: 81px;
    padding-bottom: 31px;
    font-size: 60px;
    grid-area: title;
  }
`;

const Map = styled.iframe`
  width: 100%;
  height: 297px !important;
  border: none;
  border-radius: 20px;

  @media (min-width: 1280px) {
    width: 784px !important;
    height: 473px !important;
    grid-area: image;
  }
`;

const ContactWrapper = styled.div`
  padding: 50px 0;
  display: flex;
  flex-direction: column;

  @media (min-width: 1280px) {
    grid-area: contact;
    padding: 0;
    padding-top: 177px;
    position: sticky;
    top: 0;
  }
`;

const Item = styled.div`
  width: 268px;
  display: grid;
  grid-template-columns: 19px auto;

  svg {
    fill: #212121;
    width: 19px;
    height: 19px;
  }

  div {
    margin-left: 15px;
    margin-bottom: 29px;

    @media (min-width: 1280px) {
      margin-bottom: 42px;
    }
  }

  div > .title {
    margin: 0;
    color: #212121;
    font-size: 16px;
    margin-bottom: 5px;

    @media (min-width: 1280px) {
      font-size: 18px;
    }
  }

  div > .description {
    margin: 0;
    color: #717371;
    font-size: 14px;
    line-height: 1.5;
    font-family: Gordita-Regular;
  }
`;

const SocialNetworks = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: center;
  grid-gap: 10px;
  margin-top: 20px;

  @media (min-width: 1280px) {
    justify-content: start;
    margin-left: 34px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 784px;
  width: 100%;

  @media (min-width: 1280px) {
    grid-area: form;
    padding-top: 91px;
  }

  .title {
    font-family: Gordita-Bold;
    font-size: 30px;
    color: #212121;
    margin: 0;

    @media (min-width: 1280px) {
      font-size: 60px;
    }
  }

  .description {
    color: #717371;
    font-size: 14px;
    margin: 0;
    padding-top: 15px;
    padding-bottom: 35px;
    line-height: 1.5;
    font-family: Gordita-Regular;

    @media (min-width: 1280px) {
      font-size: 16px;
      padding-bottom: 66px;
    }
  }

  .inputs {
    display: grid;
    grid-gap: 15px;

    .input {
      margin: 0;
      padding: 0;
      border: none;
      border-radius: 10px;
      background-color: #f8f9fa;
      font-size: 14px;
      padding: 24px 16px;
      width: 100%;
      height: 63px;
      font-family: Gordita-Regular;
    }

    .textarea {
      height: 150px;
    }
  }

  .button {
    align-self: flex-end;
    width: 157px;
    height: 42px;
    background-color: #ee3340;
    border-radius: 20px;
    margin: 50px 0;
    color: white;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;

    svg {
      fill: white;
      width: 24px;
      height: 24px;
      margin-left: 23px;
    }

    @media (min-width: 1280px) {
      margin: 72px 0;
    }
  }
`;

const Contact = () => {
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(false);
  const [values, setValues] = useState({
    name: "",
    email: "",
    telephone: "",
    comments: "",
  });

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    }
  }, []);

  function handleChange(event) {
    setValues({ ...values, [event.target.name]: event.target.value });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    const response = await fetch("/api", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    });

    if (response.ok) {
      history.push("/success");
    }
  }

  return (
    <>
      <Seo
        title="Ayssa | Contacto"
        description="Industrias, sectores y empresas líderes trabajan con nosotros"
        keywords="Aislantes Ayssa,Aislantes y Servicios, Aislamientos industriales, Aislamientos térmicos, Aislamientos térmicos industriales, Aislamientos térmicos industriales Guadalajara, Aislantes industriales en Guadalajara, Aislamientos para construcción, Aislantes y Servicios Ayssa, Refrigeración industrial, Tuberías industriales"
        url="https://aislantesyservicios.com/contact"
      />
      {isMobile ? <NavBarMobile /> : <Navbar />}
      <Wrapper id="header">
        <Title>Contáctanos</Title>
        <Map
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3733.224174821161!2d-103.37487068507352!3d20.660456786198935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428ade31b210ce1%3A0x9b0874c0aeb6942a!2sCalle%20Cipr%C3%A9s%202130%2C%20Del%20Fresno%2C%2044909%20Guadalajara%2C%20Jal.%2C%20Mexico!5e0!3m2!1sen!2sco!4v1596236075130!5m2!1sen!2sco"
          frameborder="0"
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
        />
        <ContactWrapper>
          <Item>
            <Room />
            <div>
              <p className="title">Oficinas</p>
              <p className="description">
                Ciprés 2130 Col. Del Fresno
                <br />
                Sector Juárez, CP 44900
                <br />
                Guadalajara, Jalisco
              </p>
            </div>
          </Item>
          <Item>
            <SettingsPhone />
            <div>
              <p className="title">Teléfono y WhatsApp</p>
              <p className="description">+52 33 1381 4294</p>
            </div>
          </Item>
          <SocialNetworks>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.linkedin.com/company/ayssamx"
            >
              <Linkedin color="#212121" />
            </a>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.facebook.com/ayssamx"
            >
              <Facebook color="#212121" />
            </a>
            <a
              rel="noopener noreferrer"
              target="_blank"
              className="instagram"
              href="https://www.instagram.com/ayssamx/"
            >
              <Instagram color="#212121" />
            </a>
          </SocialNetworks>
        </ContactWrapper>
        <Form method="POST" onSubmit={handleSubmit}>
          <h2 className="title">Formulario</h2>
          <p className="description">
            Queremos estar en contacto con usted para resolver sus dudas o
            preguntas. No dude en llenar el formulario o marcarnos al teléfono
          </p>
          <div className="inputs">
            <input
              name="name"
              type="text"
              className="input"
              placeholder="Nombre"
              value={values.name}
              onChange={handleChange}
            />
            <input
              name="email"
              type="email"
              className="input"
              placeholder="Email"
              value={values.email}
              onChange={handleChange}
            />
            <input
              name="telephone"
              type="tel"
              className="input"
              placeholder="Teléfono"
              value={values.telephone}
              onChange={handleChange}
            />
            <textarea
              name="comments"
              className="input textarea"
              placeholder="Comentarios"
              value={values.comments}
              onChange={handleChange}
              rows="4"
            />
          </div>
          <button className="button">
            ENVIAR <ArrowForward />
          </button>
        </Form>
      </Wrapper>
      <Footer />
    </>
  );
};

export default Contact;
