const HEADING1 = 'h1';
const HEADING2 = 'h2';
const HEADING3 = 'h3';
const HEADING4 = 'h4';
const HEADING5 = 'h5';
const HEADING6 = 'h6';
const P = 'p';
const PRE = 'pre';

const types = {
  HEADING1,
  HEADING2,
  HEADING3,
  HEADING4,
  HEADING5,
  HEADING6,
  P,
  PRE
};

export default types;
