import React from "react";
import styled from "styled-components";
import scrollIntoView from "smooth-scroll-into-view-if-needed";
import Fade from "react-reveal/Fade";

import { ArrowForward } from "Icons/ArrowForward";

const Wrapper = styled.div`
  width: 100%;
  height: 540px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-image: url("${({ image }) => image}");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;

  @media (min-width: 1024px) {
    height: 769px;
  }
`;

const Text = styled.h1`
  max-width: ${({ maxWidthText }) => maxWidthText ? `${maxWidthText}px` : "897px"};
  padding: ${({ maxWidthText }) => maxWidthText ? "1rem" : "0px"};
  width: 100%;
  font-size: 30px;
  font-family: Gordita-Bold;
  color: white;
  margin: 0;
  text-align: center;
  padding-top: 154px;

  @media (min-width: 1024px) {
    padding: 0;
    padding-top: 191px;
    font-size: 80px;
  }
`;

const ArrowWrapper = styled.div`
  position: absolute;
  top: 500px;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
    fill: white;
  }

  @media (min-width: 1024px) {
    top: 70vh;
  }
`;

export const Background = ({ image, text, maxWidthText }) => {
  function handleGoToTop() {
    const node = document.getElementById("content");

    if (node) {
      scrollIntoView(node, {
        behavior: "smooth",
        block: "start",
      });
    }
  }

  return (
    <Wrapper image={image}>
      <Fade>
        <Text maxWidthText={maxWidthText}>{text}</Text>
      </Fade>
      <ArrowWrapper onClick={handleGoToTop}>
        <ArrowForward rotate="90" />
      </ArrowWrapper>
    </Wrapper>
  );
}
