import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Landing from "Components/Views/Landing";
import SolutionsServices from "Components/Views/SolutionsServices";
import SolutionsProducts from "Components/Views/SolutionsProducts";
import SolutionProduct from "Components/Views/SolutionProduct";
import SolutionsAdvantages from "Components/Views/SolutionsAdvantages";
import AboutCompany from "Components/Views/AboutCompany";
import AboutClients from "Components/Views/AboutClients";
import AboutIndustry from "Components/Views/AboutIndustry";
import Contact from "Components/Views/Contact";
import Privacy from "Components/Views/Privacy";
import Cases from "Components/Views/Cases";
import Case from "Components/Views/Case";
import Success from "Components/Views/Success";
import { Provider as IndustryMenuProvider } from "Context/IndustryMenuContext";
import ScrollToTop from "Components/Utils/ScrollToTop";

const App = () => {
  return (
    <>
      <ScrollToTop />
      <Switch>
        <Route exact path="/company" component={AboutCompany} />
        <Route exact path="/testimonies" component={AboutClients} />
        <Route exact path="/industry" component={AboutIndustry} />
        <Route exact path="/services" component={SolutionsServices} />
        <Route exact path="/products" component={SolutionsProducts} />
        <Route exact path="/products/:id" component={SolutionProduct} />
        <Route exact path="/poliestireno" component={SolutionProduct} />
        <Route exact path="/advantages" component={SolutionsAdvantages} />
        <Route exact path="/" component={Landing} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/projects">
          <IndustryMenuProvider>
            <Cases />
          </IndustryMenuProvider>
        </Route>
        <Route exact path="/projects/:id" component={Case} />
        <Route exact path="/success" component={Success} />
        <Redirect from="*" to="/" />
      </Switch>
    </>
  );
};

export default App;
