import React from "react";
import styled from "styled-components";

import { NextButton } from "../../Molecules/NextButton";

const Wrapper = styled.div`
  max-width: 1086px;
  width: 100%;
  height: 420px;
  background-image: url("/home/Poliestireno_Medida.png");
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  position: relative;

  @media (min-width: 1024px) {
    height: 493px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 4rem;
  }
`;

const Box = styled.div`
  max-width: 497px;
  width: 100%;
`;

const Title = styled.h2`
  color: white;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  text-align: left;
  font-family: Gordita-Bold;

  @media (min-width: 1024px) {
    font-size: 40px;
  }
`;

const Description = styled.p`
  color: white;
  font-size: 14px;
  margin: 0;
  margin-top: 15px;
  margin-bottom: 25px;
  line-height: 1.5;
  font-family: Gordita-Regular;

  @media (min-width: 1024px) {
    margin: 0;
    margin-top: 15px;
    font-size: 20px;
  }
`;

export const Polystyrene = () => {
  return (
    <Wrapper>
      <Container>
        <Box>
          <Title>Fabricación de poliestireno.</Title>
          <Description>
            Contamos con fabricación propia de bloques, placas, cubos y medias
            caña de poliestireno para garantizar la calidad y tiempos de
            entrega.
          </Description>
        </Box>
        <div>
          <NextButton
            text="VER MÁS"
            isOpposite={true}
            toRoute="/poliestireno"
          />
        </div>
      </Container>
    </Wrapper>
  );
};
