import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import BaselineKeyboardArrowDown from "react-md-icon/dist/BaselineKeyboardArrowDown";

import { PublicationMenu } from "../Navbar/PublicationMenu";
import { CasesMenu } from "../Navbar/CasesMenu";
import { UpButton } from "../Navbar/UpButton";
import { Menu } from "Icons/Menu";
import { Close } from "Icons/Close";
import { useVisibility } from "../../Utils/useVisibility";

const NavBarMobileContainer = styled.div`
  position: ${({ isHome, showMenu, isVisible }) => !isHome || showMenu || isVisible ? "fixed" : "absolute"};
  top: 0;
  width: 100%;
  z-index: 10;

  .header {
    background-color: ${({ isHome, showMenu, isVisible }) => !isHome || showMenu || isVisible ? "white" : "transparent"};
    position: ${({ showMenu }) => showMenu ? "fixed" : "initial"};
    left: 0;
    right: 0;
    z-index: 20;

    .header-content {
      height: 60px;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .logo {
      img {
        width: 80px;
      }
    }

    button {
      padding: 0;
      border: none;
      outline: none;
      background-color: ${({ isHome, showMenu, isVisible }) => !isHome || showMenu || isVisible ? "white" : "transparent"};
    }

    button,
    svg {
      width: 24px;
      height: 24px;
      fill: ${({ isHome, showMenu, isVisible }) => !isHome || showMenu || isVisible ? "#212121" : "white"};
    }
  }

  .sub-menu {
    width: 100%;
    height: 60px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;

    .sub-menu-title,
    .sub-menu-subtitle {
      font-size: 14px;
    }

    .sub-menu-subtitle {
      display: flex;
      align-items: center;
      color: #EE3340;

      svg {
        margin-left: 10px;
      }
    }
  }

  .content {
    z-index: 10;
    width: 100%;
    height: 100vh;
    position: fixed;
    overflow-y: scroll;
    padding: 0 20px;
    padding-top: 60px;
    top: 0;
    background: white;

    .menu-section {
      padding: 32px 0;

      .menu-label {
        margin: 0;
        padding-bottom: 32px;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.75);
        font-family: Gordita-Regular;
      }

      .menu-links {
        display: grid;
        grid-gap: 20px;

        a {
          text-decoration: none;
          color: inherit;
        }

        .menu-link,
        .active-menu-link {
          font-size: 25px;
          margin: 0;
        }

        .active-menu-link {
          color: #EE3340;
        }
      }
    }
  }
`;

const Line = styled.hr`
  margin: 0;
  border: 0.5px solid #dadce0;
  opacity: 0.5;
`;

const NavBarMobile = (props) => {
  const { subMenuTitle, subMenuSubTitle, history } = props;
  const { pathname } = history.location;
  const [showMenu, setShowMenu] = useState(false);
  const { isVisible } = useVisibility();

  const activeTab =
    history.location.activeTab || history.location.pathname.slice(1);

  const rootPath = "/";

  const titles = {
    solutions: { value: "Soluciones Ayssa" },
    about: { value: "Nosotros" },
  };

  const subTitles = {
    services: { value: "Servicios" },
    products: { value: "Productos" },
    advantages: { value: "Nuestras ventajas" },
    company: { value: "Empresa" },
    testimonies: { value: "Testimonios" },
    industry: { value: "Industria" },
    privacy: { value: "Privacidad" },
  };

  let subTitle = "";

  const isRouteWithSubtitle =
    activeTab &&
    !activeTab.includes("/") &&
    activeTab !== "projects" &&
    activeTab !== "poliestireno" &&
    activeTab !== "contact";

  if (isRouteWithSubtitle) {
    subTitle = subTitles[activeTab].value;
  }

  const title = subMenuTitle && titles[subMenuTitle].value;

  const handleClick = () => {
    setShowMenu((prevState) => !prevState);
  };

  return (
    <>
      <NavBarMobileContainer
        isHome={pathname === "/"}
        showMenu={showMenu}
        isVisible={isVisible}
      >
        <div className="header">
          <div className="header-content">
            <div className="logo">
              <Link to={rootPath}>
                <img
                  src={pathname !== "/" || showMenu || isVisible ? "/logo.svg" : "/logo_white.svg"}
                  alt="logo"
                />
              </Link>
            </div>
            <button onClick={handleClick}>
              {showMenu ? <Close /> : <Menu />}
            </button>
          </div>

          {subMenuSubTitle && subMenuSubTitle !== "Cases" && !showMenu ? (
            <PublicationMenu
              subMenuSubTitle={subMenuSubTitle}
              link={subMenuSubTitle === "Productos" ? "/products" : "/projects"}
            />
          ) : null}

          {subMenuSubTitle === "Cases" && !showMenu ? <CasesMenu /> : null}
        </div>
        {subMenuTitle && (
          <div className="sub-menu">
            <div className="sub-menu-title">{title}</div>
            <div className="sub-menu-subtitle" onClick={handleClick}>
              {subTitle}
              <BaselineKeyboardArrowDown />
            </div>
          </div>
        )}
        {pathname !== "/" || isVisible ? <Line /> : null}
        {showMenu && (
          <div className="content">
            <div className="menu-section">
              <p className="menu-label">Soluciones</p>
              <div className="menu-links">
                <Link
                  to={{
                    pathname: "/services",
                    subMenuTitle: "solutions",
                    activeTab: "services",
                  }}
                >
                  {activeTab === "services" ? (
                    <p className="active-menu-link">Servicios</p>
                  ) : (
                      <p className="menu-link">Servicios</p>
                    )}
                </Link>
                <Link
                  to={{
                    pathname: "/products",
                    subMenuTitle: "solutions",
                    activeTab: "products",
                  }}
                >
                  {activeTab === "products" ? (
                    <p className="active-menu-link">Productos</p>
                  ) : (
                      <p className="menu-link">Productos</p>
                    )}
                </Link>
                <Link
                  to={{
                    pathname: "/advantages",
                    subMenuTitle: "solutions",
                    activeTab: "advantages",
                  }}
                >
                  {activeTab === "advantages" ? (
                    <p className="active-menu-link">Nuestras ventajas</p>
                  ) : (
                      <p className="menu-link">Nuestras ventajas</p>
                    )}
                </Link>
              </div>
            </div>
            <Line />
            <div className="menu-section">
              <p className="menu-label">Nosotros</p>
              <div className="menu-links">
                <Link
                  to={{
                    pathname: "/company",
                    subMenuTitle: "about",
                    activeTab: "company",
                  }}
                >
                  {activeTab === "company" ? (
                    <p className="active-menu-link">Empresa</p>
                  ) : (
                      <p className="menu-link">Empresa</p>
                    )}
                </Link>
                <Link
                  to={{
                    pathname: "/testimonies",
                    subMenuTitle: "about",
                    activeTab: "testimonies",
                  }}
                >
                  {activeTab === "testimonies" ? (
                    <p className="active-menu-link">Testimonios</p>
                  ) : (
                      <p className="menu-link">Testimonios</p>
                    )}
                </Link>
                <Link
                  to={{
                    pathname: "/industry",
                    subMenuTitle: "about",
                    activeTab: "industry",
                  }}
                >
                  {activeTab === "industry" ? (
                    <p className="active-menu-link">Industrias</p>
                  ) : (
                      <p className="menu-link">Industrias</p>
                    )}
                </Link>
              </div>
            </div>
            <Line />
            <div className="menu-section">
              <div className="menu-links">
                <Link to="/projects">
                  {activeTab === "cases" ? (
                    <p className="active-menu-link">Proyectos</p>
                  ) : (
                      <p className="menu-link">Proyectos</p>
                    )}
                </Link>
                <Link to="/contact">
                  {activeTab === "contact" ? (
                    <p className="active-menu-link">Contacto</p>
                  ) : (
                      <p className="menu-link">Contacto</p>
                    )}
                </Link>
              </div>
            </div>
          </div>
        )}
      </NavBarMobileContainer>
      <UpButton />
    </>
  );
};

export default withRouter(NavBarMobile);
