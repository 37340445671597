import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Navbar from "Components/Molecules/Navbar";
import NavBarMobile from "Components/Molecules/NavBarMobile";
import { ButtonContainer } from "Components/ButtonContainer";
import { Seo } from "Components/Utils/Seo";
import { Background } from "Components/Molecules/Background";
import Footer from "Components/Molecules/Footer";
import ContactCard from "Components/Molecules/ContactCard";
import { ArrowForward } from "Icons/ArrowForward";
import Quality from "./Quality";
import Date from "./Date";

const Wrapper = styled.div`
  padding-top: 118px;

  @media (min-width: 1024px) {
    padding-top: 148px;
  }
`;

const ButtonWrapper = styled.div`
  max-width: 1086px;
  width: 100%;
  margin: 0 auto;
  padding: 50px 0;
  display: flex;
  justify-content: flex-end;

  @media (min-width: 1024px) {
    padding: 100px 0;
  }
`;

const AboutCompany = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    }
  }, []);

  return (
    <>
      <Seo
        title="Ayssa | Empresa"
        description="40 años de experiencia respaldan nuestro prestigio"
        keywords="Aislantes Ayssa, Aislantes y Servicios, Aislamientos industriales, Aislamientos térmicos, Aislamientos térmicos industriales, Aislamientos térmicos industriales Guadalajara, Aislantes industriales en Guadalajara, Aislamientos para construcción, Aislantes y Servicios Ayssa, Refrigeración industrial, Tuberías industriales"
        url="https://aislantesyservicios.com/company"
      />
      {isMobile ? (
        <NavBarMobile subMenuTitle="about" />
      ) : (
          <Navbar subMenuTitle="about" />
        )}
      <Wrapper id="header">
        <Background
          image="/nosotros/Banners/EMPRESA.png"
          text="Ayssa es una marca que se porta con orgullo"
          maxWidthText={927}
        />
        <div id="content">
          <Date />
          <Quality />
          <ButtonWrapper>
            <ButtonContainer>
              <Link to="/testimonies" className="button-container">
                <button>TESTIMONIOS</button>
                <ArrowForward />
              </Link>
            </ButtonContainer>
          </ButtonWrapper>
        </div>
      </Wrapper>
      <ContactCard />
      <Footer />
    </>
  );
};

export default AboutCompany;
