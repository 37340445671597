import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Room } from "Icons/Room";
import { SettingsPhone } from "Icons/SettingsPhone";
import { ArrowForward } from "Icons/ArrowForward";

const Wrapper = styled.div`
  height: 784px;
  width: 100%;
  background-image: url("/Contactanos.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    justify-content: start;
  }
`;

const Box = styled.div`
  max-width: 488px;
  width: 100%;
  border-radius: 10px;
  background-color: white;
  margin: 0 20px;
  padding: 46px 29px 41px 29px;

  @media (min-width: 768px) {
    padding: 64px 60px 45px 60px;
    margin: 0;
    margin-left: 140px;
  }
`;

const Title = styled.h3`
  font-size: 25px;
  font-family: Gordita-Bold;
  color: #212121;
  margin: 0;
  margin-bottom: 32px;

  @media (min-width: 768px) {
    font-size: 40px;
  }
`;

const Item = styled.div`
  width: 268px;
  display: grid;
  grid-template-columns: 19px auto;

  svg {
    fill: #212121;
    width: 19px;
    height: 19px;
  }

  div {
    margin-left: 15px;
    margin-bottom: 29px;

    @media (min-width: 768px) {
      margin-bottom: 24px;
    }
  }

  div > .title {
    margin: 0;
    color: #212121;
    font-size: 16px;
    margin-bottom: 5px;
  }

  div > .description {
    margin: 0;
    color: #717371;
    font-size: 14px;
    line-height: 1.5;
    font-family: Gordita-Regular;
  }
`;

const FormLink = styled(Link)`
  width: 244px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
  cursor: pointer;

  @media (min-width: 768px) {
    margin-top: 65px;
    margin-left: 124px;
  }

  span {
    color: #377dff;
    font-size: 14px;
  }

  svg {
    fill: #377dff;
  }
`;

const ContactCard = () => {
  return (
    <Wrapper>
      <Box>
        <Title>Contáctanos</Title>
        <Item>
          <Room />
          <div>
            <p className="title">Oficinas</p>
            <p className="description">
              Ciprés 2130 Col. Del Fresno
              <br />
              Sector Juárez, CP 44900
              <br />
              Guadalajara, Jalisco
            </p>
          </div>
        </Item>
        <Item>
          <SettingsPhone />
          <div>
            <p className="title">Teléfono y WhatsApp</p>
            <p className="description">+52 33 1381 4294</p>
          </div>
        </Item>
        <FormLink to="/contact">
          <span>RESPONDER FORMULARIO</span>
          <ArrowForward />
        </FormLink>
      </Box>
    </Wrapper>
  );
};

export default ContactCard;
