import React from "react";
import styled from "styled-components";

import { NextButton } from "../../Molecules/NextButton";

const Wrapper = styled.div`
  max-width: 1086px;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
`;

const Image = styled.div`
  height: 420px;
  background-image: url("${(props) => props.image}");
  background-position: center;
  background-size: cover;
  position: relative;
  border-radius: 10px;

  @media (min-width: 768px) {
    height: 507px;
    transition: transform 0.2s ease-in;

    &:hover {
      transform: scale(1.05);
    }
  }
`;

const Text = styled.div`
  position: absolute;
  bottom: 0;
  padding: 2rem 20px;

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 4rem;
  }
`;

const Title = styled.h2`
  color: white;
  font-size: 18px;
  font-family: Gordita-Bold;
  padding-bottom: 3rem;
  margin: 0;
  text-align: left;

  @media (min-width: 768px) {
    width: 477px;
    font-size: 40px;
    padding-bottom: 0;
  }
`;

export const Card = ({ id, title, image }) => {
  return (
    <Wrapper>
      <Image image={image}>
        <Text>
          <Title>{title}</Title>
          <NextButton
            text="LEER PROYECTO"
            isOpposite={true}
            toRoute={`/projects/${id}`}
          />
        </Text>
      </Image>
    </Wrapper>
  );
};
