const Brands = [
  {
    img: "/clients/1-ayssa-clientes.jpg",
  },
  {
    img: "/clients/2-ayssa-clientes.jpg",
  },
  {
    img: "/clients/3-ayssa-clientes.jpg",
  },
  {
    img: "/clients/4-ayssa-clientes.jpg",
  },
  {
    img: "/clients/5-ayssa-clientes.jpg",
  },
  {
    img: "/clients/6-ayssa-clientes.jpg",
  },
  {
    img: "/clients/7-ayssa-clientes.jpg",
  },
  {
    img: "/clients/8-ayssa-clientes.jpg",
  },
  {
    img: "/clients/9-ayssa-clientes.jpg",
  },
  {
    img: "/clients/10-ayssa-clientes.jpg",
  },
  {
    img: "/clients/11-ayssa-clientes.jpg",
  },
  {
    img: "/clients/12-ayssa-clientes.png",
  },
  {
    img: "/clients/13-ayssa-clientes.jpg",
  },
  {
    img: "/clients/14-ayssa-clientes.jpg",
  },
  {
    img: "/clients/15-ayssa-clientes.png",
  },
  {
    img: "/clients/16-ayssa-clientes.png",
  },
  {
    img: "/clients/17-ayssa-clientes.png",
  },
  {
    img: "/clients/18-ayssa-clientes.jpg",
  },
  {
    img: "/clients/19-ayssa-clientes.jpg",
  },
  {
    img: "/clients/20-ayssa-clientes.jpg",
  },
  {
    img: "/clients/21-ayssa-clientes.jpg",
  },
  {
    img: "/clients/22-ayssa-clientes.jpg",
  },
  {
    img: "/clients/23-ayssa-clientes.jpg",
  },
  {
    img: "/clients/24-ayssa-clientes.jpg",
  },
  {
    img: "/clients/25-ayssa-clientes.jpg",
  },
  {
    img: "/clients/26-ayssa-clientes.jpg",
  },
  {
    img: "/clients/27-ayssa-clientes.jpg",
  },
  {
    img: "/clients/28-ayssa-clientes.jpg",
  },
  {
    img: "/clients/29-ayssa-clientes.jpg",
  },
  {
    img: "/clients/30-ayssa-clientes.jpg",
  },
  {
    img: "/clients/31-ayssa-clientes.jpg",
  },
  {
    img: "/clients/32-ayssa-clientes.png",
  },
  {
    img: "/clients/33-ayssa-clientes.png",
  },
  {
    img: "/clients/34-ayssa-clientes.png",
  },
  {
    img: "/clients/35-ayssa-clientes.jpg",
  },
  {
    img: "/clients/36-ayssa-clientes.jpg",
  },
  {
    img: "/clients/37-ayssa-clientes.jpg",
  },
  {
    img: "/clients/38-ayssa-clientes.jpg",
  },
  {
    img: "/clients/39-ayssa-clientes.jpg",
  },
  {
    img: "/clients/40-ayssa-clientes.jpg",
  },
  {
    img: "/clients/41-ayssa-clientes.jpg",
  },
  {
    img: "/clients/42-ayssa-clientes.jpg",
  },
  {
    img: "/clients/43-ayssa-clientes.jpg",
  },
  {
    img: "/clients/44-ayssa-clientes.jpg",
  },
  {
    img: "/clients/45-ayssa-clientes.jpg",
  },
  {
    img: "/clients/46-ayssa-clientes.jpg",
  },
  {
    img: "/clients/47-ayssa-clientes.jpg",
  },
  {
    img: "/clients/48-ayssa-clientes.jpg",
  },
  {
    img: "/clients/49-ayssa-clientes.jpg",
  },
];

export default Brands;
