export const List = {
  1980: {
    title: "1980,\n Nuestros inicios",
    description:
      "Iniciamos con un servicio básico de aislamiento para ayudar a las empresas que en aquel momento no tenían la asesoría ni la tecnología necesaria para solventar sus necesidades.",
    image: "/nosotros/LINEA_TIEMPO/HISTORIA.png",
  },
  2000: {
    title: "2000,\n La década del crecimiento",
    description:
      "Una nueva década y un nuevo período de crecimiento. Nuestra empresa se profesionaliza, el equipo crece y recibimos múltiples premios y reconocimientos a nivel nacional.",
    image: "/nosotros/LINEA_TIEMPO/2000.jpg",
  },
  2010: {
    title: "2010,\n Una nueva visión",
    description:
      "Al cumplir 30 años teníamos una nueva meta, crear una marca que nuestros clientes identificaran y con la que nuestro equipo de trabajo se sintiera identificado. No ha sido tarea sencilla pero sabemos del valor que nuestra marca ahora representa.",
    image: "/nosotros/LINEA_TIEMPO/2010.png",
  },
  2020: {
    title: "Ayssa,\n Una marca sólida y de prestigio",
    description:
      "Seguimos adelante y en nuestros 40 años queremos ser los mejores de América Latina. Nuestro enfoque en esta década es consolidar un sueño que la familia Ayssa comparte.",
    image: "/nosotros/LINEA_TIEMPO/2020.png",
  },
};
