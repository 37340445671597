import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { ArrowForward } from "Icons/ArrowForward";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .title {
    font-family: Gordita-Bold;
    font-size: 30px;
    color: #212121;
    margin: 0;

    @media (min-width: 768px) {
      font-size: 50px;
    }
  }

  .description {
    font-size: 14px;
    color: #717371;
    margin: 0;
    padding-top: 14px;
    padding-bottom: 68px;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 16px;
      padding-top: 10px;
      padding-bottom: 50px;
    }
  }

  .button {
    margin: 0;
    padding: 0;
    width: 157px;
    height: 42px;
    background-color: #ee3340;
    border-radius: 20px;
    color: white;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;

    svg {
      fill: white;
      width: 24px;
      height: 24px;
      margin-left: 23px;
    }
  }
`;

const Success = () => {
  const history = useHistory();

  return (
    <Wrapper>
      <p className="title">
        Formulario enviado
      </p>
      <p className="description">
        En breve nos comunicaremos con usted para responder sus dudas
      </p>
      <button
        className="button"
        onClick={() => history.push('/')}
      >
        INICIO <ArrowForward />
      </button>
    </Wrapper>
  )
};

export default Success;
