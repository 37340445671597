import styled from 'styled-components';

export const ButtonContainer = styled.div`
  .button-container {
    display: flex;
    align-items: center;
    color: #000;
    text-decoration: none;

    button {
      border: none;
      background-color: transparent;
      font-size: 14px;
      font-weight: bold;
      padding: 0;
      cursor: pointer;
      outline: none;
    }

    svg {
      width: 16px;
      height: 16px;
      margin: 0 10px;

      @media (min-width: 1024px) {
        margin: 0 19px;
      }
    }
  }
`;
