import React from "react";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";
import BaselineKeyboardArrowDown from "react-md-icon/dist/BaselineKeyboardArrowDown";

import { PublicationMenu } from "./PublicationMenu";
import { CasesMenu } from "./CasesMenu";
import { UpButton } from "./UpButton";
import { useVisibility } from "../../Utils/useVisibility";

const Wrapper = styled.nav`
  margin: 0 auto;
  width: 100%;
  position: ${({ isHome, isVisible }) => !isHome || isVisible ? "fixed" : "absolute"};;
  top: 0;
  z-index: 5;
  background-color: ${({ isHome, isVisible }) => !isHome || isVisible ? "white" : "transparent"};
`;

const NavBarContainer = styled.div`
  height: 85px;
  padding: 0 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 5;

  .logo-container {
    cursor: pointer;

    img {
      width: 135px;
      height: 50px;
    }
  }

  .links-container {
    width: 553px;
    display: grid;
    grid-template-columns: repeat(4, auto);
    column-gap: 40px;
    align-items: center;

    .link {
      font-size: 14px;
      position: relative;
      display: inline-block;

      a {
        text-decoration: none;
        color: ${({ isHome, isVisible }) => !isHome || isVisible ? "#212121" : "white"};

        &:hover {
          color: #EE3340;
          text-decoration: underline;
        }
      }

      span {
        border: none;
        padding: 0;
        outline: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        color: ${({ isHome, isVisible }) => !isHome || isVisible ? "#212121" : "white"};

        svg {
          margin-left: 10px;
        }

        &:hover {
          color: #EE3340;
          text-decoration: underline;
          svg {
            transition-duration: 0.3s;
            transition-property: transform;

            transform: rotate(-180deg);
            -webkit-transform: rotate(-180deg);
          }
        }
      }

      &:hover .hover-menu {
        display: block;
        
        a {
          color: #212121;
        }
      }
    }

    .active-link {
      span {
        color: #EE3340;
        text-decoration: underline;
      }

      span > a {
        color: #EE3340;
        text-decoration: underline;
      }
    }
  }

  .contact-button {
    background-color: #EE3340;
    border-radius: 20px;
    width: 149px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    a {
      font-size: 14px;
      color: white;
    }
  }
`;

const HoverMenuContainer = styled.div`
  width: 223px;
  height: 168px;
  border-radius: 5px;
  box-shadow: 0px 0.5px 6px #00000029;
  background-color: white;
  position: absolute;
  padding: 20px;
  z-index: 3;
  display: none;

  .hover-menu-content {
    display: flex;
    flex-direction: column;
  }

  a {
    height: 39px;
    display: flex;
    align-items: center;
    text-decoration: none;

    &:hover {
      color: #EE3340;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

const SubMenuContainer = styled.div`
  max-width: 1366px;
  width: 100%;
  height: 63px;
  padding: 0 80px;
  display: flex;
  align-items: center;
  z-index: 5;

  .sub-menu-title {
    font-size: 20px;
  }

  .links-container {
    margin-left: 60px;
    height: 66px;
    display: flex;
    flex-direction: row;

    .link {
      display: flex;
      align-items: center;
      margin-right: 60px;

      a {
        font-size: 14px;
        text-decoration: none;
        color: #000;
      }
    }

    .active-link {
      display: flex;
      align-items: center;
      border-bottom: 2px solid #EE3340;
      margin-right: 60px;

      a {
        font-size: 14px;
        text-decoration: none;
        color: #EE3340;
      }
    }
  }
`;

const Line = styled.hr`
  margin: 0;
  border: 0.5px solid #dadce0;
  opacity: 0.5;
`;

const HoverMenu = (props) => {
  const { link } = props;

  return (
    <HoverMenuContainer className="hover-menu">
      <div className="hover-menu-content">
        {link === "solutions" ? (
          <>
            <Link
              to={{
                pathname: "/services",
                subMenuTitle: "solutions",
                activeTab: "services",
              }}
            >
              Servicios
            </Link>
            <Link
              to={{
                pathname: "/products",
                subMenuTitle: "solutions",
                activeTab: "products",
              }}
            >
              Productos
            </Link>
            <Link
              to={{
                pathname: "/advantages",
                subMenuTitle: "solutions",
                activeTab: "advantages",
              }}
            >
              Nuestras ventajas
            </Link>
          </>
        ) : (
            <>
              <Link
                to={{
                  pathname: "/company",
                  subMenuTitle: "about",
                  activeTab: "company",
                }}
              >
                Empresa
            </Link>
              <Link
                to={{
                  pathname: "/testimonies",
                  subMenuTitle: "about",
                  activeTab: "testimonies",
                }}
              >
                Testimonios
            </Link>
              <Link
                to={{
                  pathname: "/industry",
                  subMenuTitle: "about",
                  activeTab: "industry",
                }}
              >
                Industrias
            </Link>
            </>
          )}
      </div>
    </HoverMenuContainer>
  );
};

const SubMenu = ({ title = "Soluciones Ayssa", subMenuTitle, history }) => {
  const activeTab =
    history.location.activeTab || history.location.pathname.slice(1);

  return (
    <SubMenuContainer>
      <div className="sub-menu-title">{title}</div>
      <div className="links-container">
        {subMenuTitle === "solutions" ? (
          <>
            <div className={activeTab === "services" ? "active-link" : "link"}>
              <Link
                to={{
                  pathname: "/services",
                  subMenuTitle: "solutions",
                  activeTab: "services",
                }}
              >
                SERVICIOS
              </Link>
            </div>
            <div className={activeTab === "products" ? "active-link" : "link"}>
              <Link
                to={{
                  pathname: "/products",
                  subMenuTitle: "solutions",
                  activeTab: "products",
                }}
              >
                PRODUCTOS
              </Link>
            </div>
            <div
              className={activeTab === "advantages" ? "active-link" : "link"}
            >
              <Link
                to={{
                  pathname: "/advantages",
                  subMenuTitle: "solutions",
                  activeTab: "advantages",
                }}
              >
                VENTAJAS
              </Link>
            </div>
          </>
        ) : (
            <>
              <div className={activeTab === "company" ? "active-link" : "link"}>
                <Link
                  to={{
                    pathname: "/company",
                    subMenuTitle: "about",
                    activeTab: "company",
                  }}
                >
                  EMPRESA
              </Link>
              </div>
              <div className={activeTab === "testimonies" ? "active-link" : "link"}>
                <Link
                  to={{
                    pathname: "/testimonies",
                    subMenuTitle: "about",
                    activeTab: "testimonies",
                  }}
                >
                  TESTIMONIOS
              </Link>
              </div>
              <div className={activeTab === "industry" ? "active-link" : "link"}>
                <Link
                  to={{
                    pathname: "/industry",
                    subMenuTitle: "about",
                    activeTab: "industry",
                  }}
                >
                  INDUSTRIAS
              </Link>
              </div>
            </>
          )}
      </div>
    </SubMenuContainer>
  );
};

const NavBar = (props) => {
  const { subMenuTitle, subMenuSubTitle, history } = props;
  const { pathname } = history.location;
  const { isVisible } = useVisibility();

  const titles = {
    solutions: { value: "Soluciones Ayssa" },
    about: { value: "Nosotros" },
  };

  const title = subMenuTitle && titles[`${subMenuTitle}`].value;

  const goToHome = () => {
    history.push("/");
  };

  return (
    <>
      <Wrapper
        isHome={pathname === "/"}
        isVisible={isVisible}
      >
        <NavBarContainer
          isHome={pathname === "/"}
          isVisible={isVisible}
        >
          <div
            className="logo-container"
            onClick={goToHome}
          >
            <img
              src={pathname !== "/" || isVisible ? "/logo.svg" : "/logo_white.svg"}
              alt="logo"
            />
          </div>
          <div className="links-container">
            <div
              className={
                subMenuTitle === "solutions" ? "link active-link" : "link"
              }
            >
              <span>
                SOLUCIONES <BaselineKeyboardArrowDown />
              </span>
              <HoverMenu link="solutions" />
            </div>
            <div
              className={subMenuTitle === "about" ? "link active-link" : "link"}
            >
              <span>
                NOSOTROS
                <BaselineKeyboardArrowDown />
              </span>
              <HoverMenu link="about" />
            </div>
            <div className="link">
              <span>
                <Link to="/projects">PROYECTOS</Link>
              </span>
            </div>
            <div className="contact-button">
              <Link to="/contact">CONTACTO</Link>
            </div>
          </div>
        </NavBarContainer>
        {subMenuTitle && (
          <SubMenu
            title={title}
            subMenuTitle={subMenuTitle}
            history={history}
          />
        )}
        {subMenuSubTitle && subMenuSubTitle !== "Cases" ? (
          <PublicationMenu
            subMenuSubTitle={subMenuSubTitle}
            link={subMenuSubTitle === "Productos" ? "/products" : "/projects"}
          />
        ) : null}
        {subMenuSubTitle === "Cases" && <CasesMenu />}
        {pathname !== "/" || isVisible ? <Line /> : null}
      </Wrapper>
      <UpButton />
    </>
  );
};

export default withRouter(NavBar);
