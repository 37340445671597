/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:8bf9fb50-44fa-4ee8-a886-5fc0a74f2fce",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_BJoWseOw6",
    "aws_user_pools_web_client_id": "3ln229ust87nr2ggv4d610romr",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://qzufoj07tb.execute-api.us-east-2.amazonaws.com/master",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://gbxapiz4vjfw3ewza4tyhrbqsq.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "shooterappef03c562be1546f7b5e5e98204a8cf59175510-master",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
