import React from 'react';
import styled from "styled-components";
import Fade from "react-reveal/Fade";

import { Card } from "./Card";

const QUALITY = [
  {
    img: "/nosotros/VALORES/CALIDAD.svg",
    title: "Calidad",
    subtitle: "Un principio que tenemos presente en nuestra atención, materiales, procesos y servicios.",
  },
  {
    img: "/nosotros/VALORES/CONFIANZA.svg",
    title: "Confianza",
    subtitle: "Somos una marca que establece vínculos profesionales a través de la honestidad y confianza.",
  },
  {
    img: "/nosotros/VALORES/CERCANIA.svg",
    title: "Cercanía",
    subtitle: "Estamos para cuidar a nuestros clientes. Siempre atentos, siempre presentes.",
  },
  {
    img: "/nosotros/VALORES/EXPERIENCIA.svg",
    title: "Experiencia",
    subtitle: "Los 40 años en la industria respaldan nuestro trabajo. Somos un referente.",
  },
  {
    img: "/nosotros/VALORES/CAPACIDAD.svg",
    title: "Capacidad",
    subtitle: "Nuestro equipo de trabajo está calificado para atender cualquier tipo de industria. Nuestro equipo porta la marca con orgullo.",
  },
  {
    img: "/nosotros/VALORES/RESULTADOS.svg",
    title: "Resultados",
    subtitle: "Cada proyecto demanda resultados específicos y lograrlos es lo que nos mantiene como una empresa de clase mundial.",
  }
];

const Wrapper = styled.div`
  background-color: #F8F9FA;
  padding: 50px 0;

  @media (min-width: 1024px) {
    padding: 100px 0;
  }

  .title {
    font-family: Gordita-Bold;
    font-size: 25px;
    text-align: center;
    color: #212121;
    width: 320px;
    margin: 0 auto;
    margin-bottom: 50px;

    @media (min-width: 1024px) {
      font-size: 50px;
      margin-bottom: 100px;
      width: 494px;
    }
  }

  .cards {
    display: grid;
    justify-content: center;
    grid-gap: 50px;

    @media (min-width: 1024px) {
      grid-template-columns: repeat(2, auto);
      grid-row-gap: 70px;
      grid-column-gap: 142px;
    }

    @media (min-width: 1280px) {
      grid-template-columns: repeat(3, auto);
    }
  }
`;

const LandingQuality = () => (
  <Wrapper>
    <Fade bottom>
      <h3 className="title">
        Los valores de una empresa exitosa
      </h3>
    </Fade>
    <div className="cards">
      {QUALITY.map((item, key) => (
        <Card
          id={key}
          key={key}
          {...item}
        />
      ))}
    </div>
  </Wrapper>
);

export default LandingQuality;