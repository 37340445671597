import React from "react";
import styled from "styled-components";
import media from "./media";
import { center, row, fullscreen } from "./utils";

export const StyledContainer = styled.div`
		max-width: ${(props) => (!props.maxWidth ? "none" : props.maxWidth)};
  	width: ${(props) => (!props.width ? "100%" : props.width)};
   ${(props) => props.height && `height:${props.height};`}
  	margin: ${(props) => (!props.margin ? "0" : props.margin)};

  	${(props) => props.background && `background: ${props.background};`}
	${(props) => props.color && `color: ${props.color};`}

  	${(props) => props.marginCenter && "margin: 0 auto;"}
  	
  	${(props) =>
      props.circle &&
      `
  		height:${props.circle};
  		width:${props.circle};
  		border-radius: 50%;
  		overflow: hidden;
  		text-align: cente;
  		padding: 0;
  		${center}
  	`}

  	${(props) =>
      props.backgroundImg &&
      `
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		background-image: url(${props.backgroundImg} );

  	`}


	



  	${(props) => props.overflowHidden && `overflow:hidden;`}
  	${(props) => props.overflowXScroll && `overflow-x:scroll;`}
  	${(props) => props.overflowYScroll && `overflow-y:scroll;`}


  	${(props) => props.defaultBorder && `border: 1px solid rgba(0,0,0,0.2);`}
	${(props) => props.border && `border: ${props.border};`}

  

  	${(props) =>
      props.defaultShadow && `box-shadow: 0 0.5px 3px rgba(0,0,0,0.2);`}
	${(props) => props.shadow && `box-shadow: ${props.shadow};`}


	${(props) => props.center && ` ${center}`}
  	${(props) => props.row && ` ${row}`}
  	${(props) => props.fullscreen && ` ${fullscreen}`}


	${(props) => props.css && `${props.css}`}
	${(props) => props.desktopCSS && media().desktop`${props.desktopCSS}`}
	${(props) => props.tabletCSS && media().tablet`${props.tabletCSS}`}
	${(props) => props.phoneCSS && media().phone`${props.phoneCSS}`}
	${(props) =>
    props.mediaCSS && media(props.mediaCSS[0]).desktop`${props.mediaCSS[1]}`}

`;

export const Generator = (props) => styled.div`

  	width: ${!props.width ? "100%" : props.width};
   ${props.height && `height:${props.height};`}
  	margin: ${!props.margin ? "0" : props.margin};

  	${props.background && `background: ${props.background};`}
	${props.color && `color: ${props.color};`}

  	${props.marginCenter && "margin: 0 auto;"}
  	
  	${
      props.circle &&
      `
  		height:${props.circle};
  		width:${props.circle};
  		border-radius: 50%;
  		overflow: hidden;
  		text-align: cente;
  		padding: 0;
  		${center}
  	`
    }

  	${
      props.backgroundImg &&
      `
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		background-image: url(${props.backgroundImg} );

  	`
    }


	



  	${props.overflowHidden && `overflow:hidden;`}
  	${props.overflowXScroll && `overflow-x:scroll;`}
  	${props.overflowYScroll && `overflow-y:scroll;`}


  	${props.defaultBorder && `border: 1px solid rgba(0,0,0,0.2);`}
	${props.border && `border: ${props.border};`}

  

  	${props.defaultShadow && `box-shadow: 0 0.5px 3px rgba(0,0,0,0.2);`}
	${props.shadow && `box-shadow: ${props.shadow};`}


	${props.center && ` ${center}`}
  	${props.row && ` ${row}`}
  	${props.fullscreen && ` ${fullscreen}`}


	${props.css && `${props.css}`}
	${props.desktopCSS && media().desktop`${props.desktopCSS}`}
	${props.tabletCSS && media().tablet`${props.tabletCSS}`}
	${props.phoneCSS && media().phone`${props.phoneCSS}`}
	${props.mediaCSS && media(props.mediaCSS[0]).desktop`${props.mediaCSS[1]}`}

`;

export const ImgSource = styled.img`
	
	width: 100%;

	${(props) => props.css && `${props.css}`}
	${(props) => props.desktopCSS && media().desktop`${props.desktopCSS}`}
	${(props) => props.tabletCSS && media().tablet`${props.tabletCSS}`}
	${(props) => props.phoneCSS && media().phone`${props.phoneCSS}`}
	${(props) =>
    props.mediaCSS && media(props.mediaCSS[0]).desktop`${props.mediaCSS[1]}`}

`;

export const Img = (props) => {
  const { src = "", img = {} } = props;

  return (
    <React.Fragment>
      <Container {...props}>
        <ImgSource {...img} src={src} />
      </Container>
    </React.Fragment>
  );
};

export const Container = (props) => {
  const { children, wrapper } = props;

  const styles = wrapper ? wrapper : props;

  return (
    <StyledContainer {...styles}>
      {wrapper ? (
        <StyledContainer {...props}>{children}</StyledContainer>
      ) : (
        children
      )}
    </StyledContainer>
  );
};

/*

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: ${props => props.src? "url(" +props.src+ ")": "url('http://placehold.it/50x50')"};

*/
