import React from "react";

export function Linkedin({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.557"
      height="22.557"
      viewBox="0 0 22.557 22.557"
    >
      <path
        fill={color}
        d="M11.278 0a11.278 11.278 0 1011.279 11.278A11.28 11.28 0 0011.278 0zM8 17.05H5.254V8.786H8zM6.628 7.657H6.61A1.432 1.432 0 116.646 4.8a1.432 1.432 0 11-.018 2.855zm11.277 9.393h-2.746v-4.421c0-1.111-.4-1.869-1.392-1.869a1.5 1.5 0 00-1.409 1 1.881 1.881 0 00-.09.67v4.62H9.521s.036-7.488 0-8.264h2.747v1.17a2.727 2.727 0 012.475-1.364c1.807 0 3.162 1.181 3.162 3.719zm0 0"
        data-name="linkedin (1)"
      ></path>
    </svg>
  );
}
