import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import API from "@aws-amplify/api";
import Navbar from "Components/Molecules/Navbar";
import NavBarMobile from "Components/Molecules/NavBarMobile";
import { Tag } from "./Tag";
import Footer from "Components/Molecules/Footer";
import { Explore } from "./Explore";
import { Serialize } from "Lib/serialize";
import { Seo } from "Components/Utils/Seo";
import { AUTH_MODE } from "Lib/authMode";

const Container = styled.div`
  margin: 0 auto;
  padding: 0 20px;
  padding-top: 120px;
  width: 100%;
  max-width: 1086px;

  @media (min-width: 768px) {
    padding: 0;
    padding-top: 130px;
  }
`;

const Image = styled.div`
  width: 100%;
  height: 472px;
  background-image: url("${(props) => props.image}");
  background-position: center;
  background-size: cover;
  position: relative;
  border-radius: 0px 0px 10px 10px;

  @media (min-width: 768px) {
    height: 600px;
  }
`;

const Text = styled.div`
  position: absolute;
  bottom: 0;
  padding: 3rem 20px;
  color: white;
  font-family: Gordita-Bold;
  font-size: 22px;

  @media (min-width: 768px) {
    padding: 4rem;
    width: 715px;
    font-size: 50px;
  }
`;

const Content = styled.div`
  padding-top: 2rem;

  @media (min-width: 768px) {
    padding-top: 6rem;
    padding-right: 10rem;
    padding-left: 10rem;
  }

  .shooter-title,
  .shooter-subtitle {
    font-size: 20px;
    color: #212121;
    font-weight: 500;
    margin: 0;
    margin-bottom: 1rem;
    font-family: Gordita-Bold;

    @media (min-width: 768px) {
      font-size: 30px;
    }
  }

  .shooter-description {
    font-size: 14px;
    color: #677282;
    text-align: left;
    line-height: 1.5;
    margin: 0;
    margin-bottom: 1rem;
    font-family: Gordita-Regular;

    @media (min-width: 768px) {
      font-size: 18px;
    }
  }

  .shooter-image {
    height: 366px;
    width: 100%;
    object-fit: cover;
    margin: 3rem 0;

    @media (min-width: 768px) {
      height: 508px;
      margin: 6rem 0;
    }
  }

  .shooter-lo-list,
  .shooter-ul-list {
    font-family: Gordita-Regular;
    font-size: 16px;
    color: #757575;
    
    li {
      margin-bottom: 0.5px;
    }
  }
`;

export const GetPublication = `
  query GetPublication($id: ID!) {
    getPublication(id: $id) {
      title
      description
      image
      tag
    }
  }
`;

const Case = () => {
  let { id } = useParams();
  const [isMobile, setIsMobile] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    }
  }, []);

  useEffect(() => {
    getData();
  }, [id]);

  async function getData() {
    const { data } = await API.graphql({
      query: GetPublication,
      variables: {
        id,
      },
      authMode: AUTH_MODE,
    });

    setData(data.getPublication);
  }

  return (
    <>
      {isMobile ? (
        <NavBarMobile subMenuSubTitle="Casos de estudio" />
      ) : (
          <Navbar subMenuSubTitle="Casos de estudio" />
        )}

      <Seo
        title="Ayssa - Aislantes y Servicios"
        description="Ayssa es la empresa líder en aislamientos industriales y servicios derivados"
        keywords="Aislantes Ayssa, Aislantes y Servicios, Aislamientos industriales, Aislamientos térmicos, Aislamientos térmicos industriales, Aislamientos térmicos industriales Guadalajara, Aislantes industriales en Guadalajara, Aislamientos para construcción, Aislantes y Servicios Ayssa, Refrigeración industrial, Tuberías industriales"
        url={`https://aislantesyservicios.com/projects/${id}`}
      />

      <Container id="header">
        {data ? (
          <>
            <Image image={data.image}>
              <Text>{data.title}</Text>
            </Image>
            <Content
              dangerouslySetInnerHTML={{
                __html: Serialize({
                  children: JSON.parse(data.description),
                }),
              }}
            />
            <Tag text={data.tag} />
          </>
        ) : (
            <h1 style={{ textAlign: "center" }}>Loading content...</h1>
          )}
      </Container>

      <Explore />
      <Footer />
    </>
  );
};

export default Case;
