import React from "react";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";

import { ArrowForward } from "Icons/ArrowForward";

const Wrapper = styled.div`
  background-color: #F8F9FA;
  height: 976px;
  width: 100%;

  @media (min-width: 1280px) {
    height: 1091px;
  }
`;

const Title = styled.h2`
  color: #212121;
  font-size: 25px;
  font-family: Gordita-Bold;
  padding-top: 50px;
  width: 275px;
  text-align: center;
  margin: 0 auto;

  @media (min-width: 1280px) {
    font-size: 60px;
    padding-top: 150px;
    width: 660px;
  }
`;

const Text = styled.p`
  color: #717371;
  font-size: 14px;
  padding-top: 15px;
  width: 321px;
  text-align: center;
  margin: 0 auto;
  line-height: 1.5;
  font-family: Gordita-Regular;

  @media (min-width: 1280px) {
    font-size: 20px;
    padding-top: 21px;
    width: 656px;
  }
`;

const Cards = styled.div`
  display: grid;
  justify-items: center;
  grid-gap: 20px;
  margin: 0 auto;
  max-width: 1086px;
  width: 100%;
  padding-top: 35px;

  @media (min-width: 1280px) {
    grid-template-columns: repeat(2, auto);
    grid-gap: 1rem;
    padding-top: 75px;
  }
`

const Picture = styled.div`
  background-image: url("${({ image }) => image}");
  background-size: cover;
  background-position: center;
  width: 320px;
  height: 286px;
  border-radius: 10px;

  @media (min-width: 1280px) {
    width: 532px;
    height: 475px;
  }
`;

const Box = styled(Link)`
  background-color: #EE3340;
  width: 320px;
  height: 56px;
  margin-top: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;

  @media (min-width: 1280px) {
    width: 532px;
    height: 66px;
    margin-top: 15px;
    padding: 0 36px;
  }

  .text {
    color: white;
    font-size: 16px;

    @media (min-width: 1280px) {
      font-size: 25px;
    }
  }

  .arrow {
    font-size: 14px;
    color: white;
    display: flex;
    align-items: center;
    margin: 0;

    span {
      display: none;

      @media (min-width: 1280px) {
        display: block;
        margin-top: 4px;
      }
    }

    svg {
      fill: white;
      width: 24px;
      height: 24px;
      margin-left: 22px;
    }
  }
`;

const Solutions = () => {
  return (
    <Wrapper>
      <Fade bottom>
        <Title>Somos una empresa de aislamiento térmico</Title>
      </Fade>
      <Fade>
        <Text>
          Ayssa es experiencia, calidad y cercanía.
          <br />
          Conoce nuestros servicios y productos complementarios.
        </Text>
      </Fade>
      <Cards>
        <div>
          <Picture image="/home/Aislamiento_Termico.png" />
          <Box to="/services">
            <span className="text">
              Aislamiento térmico
            </span>
            <span className="arrow">
              <span>LEER MÁS</span>
              <ArrowForward />
            </span>
          </Box>
        </div>
        <div>
          <Picture image="/home/Mantenimiento.png" />
          <Box to="/services">
            <span className="text">
              Mantenimiento
            </span>
            <span className="arrow">
              <span>LEER MÁS</span>
              <ArrowForward />
            </span>
          </Box>
        </div>
      </Cards>
    </Wrapper>
  )
}

export default Solutions;
