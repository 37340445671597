import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import API from "@aws-amplify/api";
import Fade from "react-reveal/Fade";

import Navbar from "Components/Molecules/Navbar";
import NavBarMobile from "Components/Molecules/NavBarMobile";
import Footer from "Components/Molecules/Footer";
import { Card } from "./Card";
import { currentISODate } from "Lib/date";
import { Context as IndustryMenuContext } from "Context/IndustryMenuContext";
import {
  SortSectionByUserIdAndCreatedAt,
  SortSectionByUserIdAndCreatedAtByTag,
} from "./queries";
import { Seo } from "Components/Utils/Seo";
import { AUTH_MODE } from "Lib/authMode";

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 120px 20px;

  @media (min-width: 1280px) {
    max-width: 1086px;
    width: 100%;
    padding: 0 4rem;
    padding: 130px 0;
  }
`;

const Title = styled.h1`
  font-family: Gordita-Bold;
  font-size: 35px;
  color: #212121;
  margin: 0;
  padding: 50px 0;
  max-width: 970px;
  width: 100%;

  @media (min-width: 1280px) {
    font-size: 70px;
    padding-top: 100px;
    padding-bottom: 70px;
  }
`;

const Cards = styled.div`
  display: grid;
  grid-gap: 10px;

  @media (min-width: 1280px) {
    grid-gap: 20px;
  }
`;

const Cases = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [cases, setCases] = useState([]);
  const { checkedElement } = useContext(IndustryMenuContext);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    }
  }, []);

  useEffect(() => {
    getCases();
  }, [checkedElement]);

  async function getCases() {
    const { data } = await API.graphql({
      query:
        checkedElement === "Todas"
          ? SortSectionByUserIdAndCreatedAt
          : SortSectionByUserIdAndCreatedAtByTag,
      variables: {
        userId: "3ce07afb-193d-4fb2-97fc-6f3bd87e4a63",
        createdAt: currentISODate,
        sectionId: "b86715a7-78f1-411f-907f-f701d3756db0",
        tag: checkedElement,
      },
      authMode: AUTH_MODE,
    });

    if (data.sortSectionByUserIdAndCreatedAt.items.length > 0) {
      setCases(
        data.sortSectionByUserIdAndCreatedAt.items[0].publications.items
      );
    }
  }

  return (
    <>
      <Seo
        title="Ayssa | Casos de estudio"
        description="Industrias, sectores y empresas líderes trabajan con nosotros"
        keywords="Aislantes Ayssa,Aislantes y Servicios, Aislamientos industriales, Aislamientos térmicos, Aislamientos térmicos industriales, Aislamientos térmicos industriales Guadalajara, Aislantes industriales en Guadalajara, Aislamientos para construcción, Aislantes y Servicios Ayssa, Refrigeración industrial, Tuberías industriales"
        url="https://aislantesyservicios.com/cases"
      />
      {isMobile ? (
        <NavBarMobile subMenuSubTitle="Cases" />
      ) : (
          <Navbar subMenuSubTitle="Cases" />
        )}
      <Wrapper id="header">
        <Fade bottom>
          <Title>
            Nuestros casos de estudio, el éxito de nuestros
            clientes
          </Title>
        </Fade>
        <Cards>
          {cases.map((item) => (
            <Card
              key={item.id}
              id={item.id}
              title={item.title}
              image={item.image}
            />
          ))}
        </Cards>
      </Wrapper>
      <Footer />
    </>
  );
};

export default Cases;
