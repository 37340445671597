import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams, useLocation } from "react-router-dom";
import API from "@aws-amplify/api";

import Navbar from "Components/Molecules/Navbar";
import NavBarMobile from "Components/Molecules/NavBarMobile";
import { Serialize } from "Lib/serialize";
import { Seo } from "Components/Utils/Seo";
import { AUTH_MODE } from "Lib/authMode";
import PoliestirenoPage from "Components/Constants/PoliestirenoPage";

const Container = styled.div`
  margin: 0 auto;
  padding: 120px 20px;
  height: 100vh;
  width: 100%;
  max-width: 1440px;

  @media (min-width: 768px) {
    padding: 130px 0;
  }
`;

const Row = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1024px) {
    flex-direction: row-reverse;
    align-items: start;
  }
`;

const Image = styled.div`
  max-width: 603px;
  width: 100%;
  height: 522px;
  border-radius: 0px 0px 10px 10px;
  margin-bottom: 3rem;
  background-image: ${({ image }) => `url('${image}')`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: 768px) {
    position: sticky;
    top: 130px;
    margin-bottom: 0;
    border-radius: 0;
    height: 100vh;
  }
`;

const Description = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    padding: 4rem;
  }

  .shooter-title,
  .shooter-subtitle {
    font-size: 20px;
    color: #212121;
    font-family: Gordita-Bold;
  }

  .shooter-description {
    font-size: 16px;
    color: #757575;
    margin: 2rem 0;
    font-family: Gordita-Regular;
    line-height: 1.5;
  }

  .shooter-image {
    height: 366px;
    width: 100%;
    object-fit: cover;
    margin: 1rem 0;

    @media (min-width: 768px) {
      height: 508px;
    }
  }

  .shooter-lo-list,
  .shooter-ul-list {
    font-family: Gordita-Regular;
    font-size: 16px;
    color: #757575;

    li {
      margin-bottom: 10px;
    }
  }
`;

const Title = styled.h1`
  font-family: Gordita-Bold;
  font-size: 30px;
  color: #212121;

  @media (min-width: 768px) {
    font-size: 50px;
  }
`;

const GetPublication = `
  query GetPublication($id: ID!) {
    getPublication(id: $id) {
      title
      description
      image
    }
  }
`;

const SolutionProduct = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const [isMobile, setIsMobile] = useState(false);
  const [data, setData] = useState();
  const isPoliestireno = pathname.includes("poliestireno");

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    }
  }, []);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    if (!pathname.includes("poliestireno")) {
      const { data } = await API.graphql({
        query: GetPublication,
        variables: {
          id,
        },
        authMode: AUTH_MODE,
      });

      setData(data.getPublication);
    } else {
      setData(PoliestirenoPage);
    }
  }

  return (
    <>
      {isMobile ? (
        <NavBarMobile subMenuSubTitle="Productos" />
      ) : (
        <Navbar subMenuSubTitle="Productos" />
      )}

      <Seo
        title="Ayssa - Aislantes y Servicios"
        description="Ayssa es la empresa líder en aislamientos industriales y servicios derivados"
        keywords="Aislantes Ayssa, Aislantes y Servicios, Aislamientos industriales, Aislamientos térmicos, Aislamientos térmicos industriales, Aislamientos térmicos industriales Guadalajara, Aislantes industriales en Guadalajara, Aislamientos para construcción, Aislantes y Servicios Ayssa, Refrigeración industrial, Tuberías industriales"
        url={`https://aislantesyservicios.com/products/${id}`}
      />

      <Container id="header">
        {data ? (
          <Row>
            <Image
              image={
                isPoliestireno
                  ? "/nosotros/Poliestireno/Poliestireno0.jpg"
                  : data.image
              }
            />
            <Description>
              <Title>{isPoliestireno ? "Poliestireno" : data.title}</Title>
              {!isPoliestireno ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: Serialize({
                      children: JSON.parse(data.description),
                    }),
                  }}
                />
              ) : (
                <div>
                  {/* Esta escrito de esta manera, porque esta simulando como se vería una publicación con shooter, en este caso, acerca de Poliestireno*/}
                  <h1 class="shooter-title">
                    <b>Poliestireno a Medida</b>
                  </h1>
                  <p class="shooter-description">
                    <span>
                      El poliestireno es una espuma plástica de carácter
                      termo-plástico constituida por un conjunto de partículas
                      de estructura celular cerradas unidas íntimamente entre
                      sí.
                    </span>
                  </p>
                  <p class="shooter-description">
                    <b></b>
                  </p>
                  <h2 class="shooter-subtitle">
                    <b>Ventajas:</b>
                  </h2>
                  <ul class="shooter-ul-list">
                    <li>
                      <span>Absorción de energía</span>
                    </li>
                    <li>
                      <span>Aislamiento acústico y termico</span>
                    </li>
                    <li>
                      <span>Fácil de manejar</span>
                    </li>
                    <li>
                      <span>Auto- extinguible</span>
                    </li>
                    <li>
                      <span>No tóxico e inodoro</span>
                    </li>
                    <li>
                      <span>Económico y reciclable</span>
                    </li>
                  </ul>
                  <p class="shooter-description">
                    <span></span>
                  </p>
                  <p class="shooter-description">
                    <span></span>
                  </p>
                  <p class="shooter-description">
                    <span>
                      Material que se adapta a las necesidades de nuestros
                      clientes.
                    </span>
                  </p>
                  <p class="shooter-description">
                    <span></span>
                  </p>
                  <p class="shooter-description">
                    <span></span>
                  </p>
                  <h2 class="shooter-subtitle">
                    <b>Formatos:</b>
                  </h2>
                  <h2 class="shooter-subtitle">
                    <b></b>
                  </h2>
                  <h2 class="shooter-subtitle">
                    <b></b>
                  </h2>
                  <p class="shooter-description">
                    <span>Placa</span>
                  </p>
                  <img
                    class="shooter-image"
                    src="/nosotros/Poliestireno/Poliestireno1.jpg"
                  />
                  <p class="shooter-description">
                    <span>Casetón</span>
                  </p>
                  <p class="shooter-description">
                    <span></span>
                  </p>
                  <img
                    class="shooter-image"
                    src="/nosotros/Poliestireno/Poliestireno2.jpg"
                  />
                  <p class="shooter-description">
                    <span>Media Caña</span>
                  </p>
                  <p class="shooter-description">
                    <span></span>
                  </p>
                  <img
                    class="shooter-image"
                    src="/nosotros/Poliestireno/Poliestireno3.jpg"
                  />
                  <p class="shooter-description">
                    <span></span>
                  </p>
                </div>
              )}
            </Description>
          </Row>
        ) : (
          <h1 style={{ textAlign: "center" }}>Loading content...</h1>
        )}
      </Container>
    </>
  );
};

export default SolutionProduct;
