import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { NextButton } from "../../Molecules/NextButton";

const Container = styled.div`
  display: grid;
  justify-items: center;
  grid-template-areas:
    "title"
    "content"
    "button";

  #next-button {
    grid-area: button;
    margin: 0;
    margin-top: 50px;
  }

  @media (min-width: 1280px) {
    justify-items: start;
    grid-template-areas:
      "title button"
      "content content";

    #next-button {
      margin: 0;
      margin-bottom: 50px;
      justify-self: flex-end;
    }
  }
`;

const Title = styled.h3`
  font-size: 18px;
  color: #212121;
  margin-bottom: 35px;
  font-family: Gordita-Bold;
  grid-area: title;
  margin: 0;
  margin-bottom: 50px;

  @media (min-width: 768px) {
    font-size: 30px;
    margin-top: 5px;
    margin-bottom: 45px;
  }
`;

const CardList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-area: content;
  grid-gap: 1rem;  

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, auto);
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(4, auto);
    grid-row-gap: 100px;
    grid-column-gap: 1rem;
  }
`;

const CardWrapper = styled.div`
  width: 156px;

  @media (min-width: 768px) {
    width: 258px;
  }
`;

const Card = styled.div`
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
`;

const CardImage = styled.div`
  height: 201px;
  border-radius: 10px;
  background-image: url("${(props) => props.image}");
  background-size: cover;
  background-position: center;

  @media (min-width: 768px) {
    height: 322px;
    transition: transform 0.2s ease-in;

    &:hover {
      transform: scale(1.1);
    }
  }
`;

const CardTitle = styled.h4`
  width: 100%;
  max-width: 256px;
  color: ${(props) =>
    props.element === props.index + 1 ? "#377DFF" : "#212121"};
  font-size: 14px;
  margin: 0;
  padding-top: 0.5rem;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const List = ({ title, cards, isList = false }) => {
  const [hoveredElement, setHoveredElement] = useState(null);

  return (
    <Container>
      <Title>{title}</Title>
      {isList && (
        <NextButton
          text="VER TODOS"
          toRoute="/products"
        />
      )}
      <CardList>
        {cards.map((content, index) => (
          <Link
            to={`/products/${content.id}`}
            key={index}
          >
            <CardWrapper isList={isList}>
              <Card
                onMouseEnter={() => setHoveredElement(index + 1)}
                onMouseLeave={() => setHoveredElement(null)}
              >
                <CardImage image={content.image} />
              </Card>
              <CardTitle
                index={index}
                element={hoveredElement}
              >
                {content.title}
              </CardTitle>
            </CardWrapper>
          </Link>
        ))}
      </CardList>
    </Container>
  );
};
