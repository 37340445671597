import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Navbar from "Components/Molecules/Navbar";
import NavBarMobile from "Components/Molecules/NavBarMobile";
import Footer from "Components/Molecules/Footer";
import ContactCard from "Components/Molecules/ContactCard";
import { Background } from "Components/Molecules/Background";
import { Cases } from "./Cases";
import { ButtonContainer } from "Components/ButtonContainer";
import { ArrowForward } from "Icons/ArrowForward";
import { Seo } from "Components/Utils/Seo";

const Wrapper = styled.div`
  padding-top: 118px;

  @media (min-width: 1024px) {
    padding-top: 148px;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 637px;
  padding: 0 1rem;

  @media (min-width: 1024px) {
    flex-direction: row;
    padding: 0;
    height: 713px;
  }

  .image {
    background-image: url('${({ image }) => image}');
    background-position: center;
    background-size: cover;
    max-width: 443px;
    width: 100%;
    height: 338px;
    border-radius: 10px;
    grid-area: image;

    @media (min-width: 1024px) {
      height: 413px;
    }
  }

  .content {
    .title {
      font-family: Gordita-Bold;
      font-size: 25px;
      color: #212121;
      margin: 0;
      margin-top: 35px;
      margin-bottom: 15px;
      width: 320px;

      @media (min-width: 1024px) {
        margin: 0;
        margin-bottom: 15px;
        margin-left: 100px;
        font-size: 50px;
        width: 543px;
      }
    }

    .description {
      font-size: 14px;
      margin: 0;
      color: #717371;
      width: 320px;
      font-family: Gordita-Regular;
      line-height: 1.5;

      @media (min-width: 1024px) {
        margin-left: 100px;
        font-size: 18px;
        width: 543px;
      }
    }
  }
`;

const CasesWrapper = styled.div`
  padding-top: 50px;
  background-color: #F8F9FA;

  @media (min-width: 1024px) {
    padding-top: 100px;
  }
`;

const ButtonWrapper = styled.div`
  max-width: 1086px;
  width: 100%;
  margin: 0 auto;
  padding: 50px 0;

  @media (min-width: 1024px) {
    padding: 100px 0;
  }
`;

const SolutionsAdvantages = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    }
  }, []);

  return (
    <>
      <Seo
        title="Ayssa | Nuestras ventajas"
        description="Nuestros servicios, productos y materiales son de gran calidad"
        keywords="Acero en rollo, Aluminio en rollo, Aislamientos industriales, Aislamiento reusable, Poliuretano espreado, Poliuretano inyectado, Poliestireno a medida, Medias cañas de fibra de vidrio, Aislamientos térmicos"
        url="https://aislantesyservicios.com/advantages"
      />
      {isMobile ? (
        <NavBarMobile subMenuTitle="solutions" />
      ) : (
          <Navbar subMenuTitle="solutions" />
        )}
      <Wrapper id="header">
        <Background
          image="/solutions/SOLUCIONES.png"
          text="Garantizamos un rendimiento óptimo"
        />
        <div id="content">
          <Card image="/solutions/MATERIALES.png">
            <div className="image" />
            <div className="content">
              <h3 className="title">Los materiales Ayssa son de primera calidad</h3>
              <p className="description">
                De esta manera podemos garantizar un rendimiento óptimo y la durabilidad de nuestro trabajo.
                Los detalles y el rendimiento hacen la gran diferencia.
              </p>
            </div>
          </Card>
          <CasesWrapper>
            <Cases />
            <ButtonWrapper>
              <ButtonContainer>
                <Link to="/products" className="button-container">
                  <ArrowForward rotate="180" />
                  <button>PRODUCTOS</button>
                </Link>
              </ButtonContainer>
            </ButtonWrapper>
          </CasesWrapper>
        </div>
        <ContactCard />
        <Footer />
      </Wrapper>
    </>
  );
};

export default SolutionsAdvantages;
