import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

import Navbar from "Components/Molecules/Navbar";
import NavBarMobile from "Components/Molecules/NavBarMobile";
import Brands from "Components/Molecules/Brands";
import { ButtonContainer } from "Components/ButtonContainer";
import { Seo } from "Components/Utils/Seo";
import ContactCard from "Components/Molecules/ContactCard";
import Footer from "Components/Molecules/Footer";
import { ArrowForward } from "Icons/ArrowForward";

const CUSTOMERS = [
  {
    title: `“Trabajamos con Ayssa por ser una compañía con la calidad y tiempos de entrega que necesitamos, cumpliendo en todo momento con los estándares de calidad, seguridad e higiene”`,
    name: "Ing. José Torres",
    subtitle: "Heineken México",
    img: "/nosotros/TESTIMONIOS/Logos/heineken-ayssa.png",
    background: "/nosotros/TESTIMONIOS/TESTIMONIO-CLIENTE-1.png",
  },
  {
    title: `“El trabajo que entregan es de buena calidad, en tiempo y de acuerdo a los planes que desarrollamos en conjunto; siempre he contado con el apoyo al momento que lo requiero, aparte que se alinean a la demanda que la empresa necesita”`,
    name: "Ing. Eduardo Chavez",
    subtitle: "Ingeniero de planta, Tequila José Cuervo",
    img: "/nosotros/TESTIMONIOS/Logos/jc-logo.png",
    background: "/nosotros/TESTIMONIOS/TESTIMONIO-CLIENTE-2.png",
    isLeft: true,
  },
  {
    title: `“La asesoría, el servicio son de manera personalizada, la atención adecuada y la garantía es un factor que los identifica. La ejecución en tiempo y forma han sido uno de los factores en los cuales se decide solicitar sus servicios”`,
    name: "Ing. Salvador Sánchez",
    subtitle: "Ingeniero de planta, Laboratorios PISA",
    img: "/nosotros/TESTIMONIOS/Logos/pisa.png",
    background: "/nosotros/TESTIMONIOS/TESTIMONIO-CLIENTE-3.png",
  },
  {
    title: `“El resultado siempre es satisfactorio. Ayssa cumple lo requerido, trabajamos por su formalidad y calidad”`,
    name: "Ing. Eduardo Servín",
    subtitle: "La Moderna SA DE CV",
    img: "/nosotros/TESTIMONIOS/Logos/la-moderna-ayssa.png",
    background: "/nosotros/TESTIMONIOS/TESTIMONIO-CLIENTE-4.png",
    isLeft: true,
  },
  {
    title: `“El personal de Ayssa es muy dedicado, conocen y saben muy bien lo que hacen; siempre mejorando y superando nuestras expectativas, cuidando hasta el más mínimo detalle”`,
    name: "Ing. Alfonso Velázquez",
    subtitle: "SESAJAL SA DE CV",
    img: "/nosotros/TESTIMONIOS/Logos/XMLID.png",
    background: "/nosotros/TESTIMONIOS/TESTIMONIO-CLIENTE-5.png",
  },
];

const Wrapper = styled.div`
  padding-top: 118px;

  @media (min-width: 768px) {
    padding-top: 148px;
  }
`;

const Background = styled.div`
  background-image: url("/nosotros/Banners/CLIENTES.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 440px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    height: 505px;
  }

  .text {
    max-width: 726px;
    width: 100%;
    font-size: 30px;
    font-family: Gordita-Bold;
    color: white;
    margin: 0;
    text-align: center;
    padding-top: 154px;

    @media (min-width: 1024px) {
      padding-top: 191px;
      font-size: 80px;
    }
  }
`;

const Container = styled.div`
  max-width: 1086px;
  width: 100%;
  margin: 0 auto;
`;

const Card = styled.div`
  display: grid;
  grid-gap: 10px;
  padding: 0 20px;
  padding-top: 50px;

  @media (min-width: 1280px) {
    grid-gap: 20px;
    padding: 0;
    padding-top: 150px;
  }
`;

const CardContent = styled.div`
  width: 100%;
  height: 656px;
  background-image: ${({ image }) => `url('${image}')`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;

  @media (min-width: 1280px) {
    display: flex;
    align-items: center;
    justify-content: ${({ isLeft }) => (isLeft ? "flex-end" : "flex-start")};
    padding-left: ${({ isLeft }) => (isLeft ? "0px" : "40px")};
    padding-right: ${({ isLeft }) => (isLeft ? "40px" : "0px")};
  }

  .box {
    background-color: white;
    max-width: 503px;
    width: 90%;
    margin: 0 auto;
    height: 435px;
    border-radius: 10px;
    margin-top: 196px;
    padding: 35px 22px;
    position: relative;

    @media (min-width: 1280px) {
      padding: 50px 41px;
      height: 383px;
      margin: 0;
    }

    .text {
      line-height: 1.5;
      font-size: 16px;
      color: #212121;
      margin: 0;

      @media (min-width: 768px) {
        font-size: 18px;
      }
    }

    .company {
      position: absolute;
      bottom: 35px;

      @media (min-width: 1024px) {
        bottom: 50px;
        display: flex;
        align-items: center;
      }

      .logo {
        max-height: 36px;
        max-width: 126px;

        @media (min-width: 1024px) {
          max-height: 52px;
          margin-right: 20px;
        }
      }

      .texts {
        .name {
          margin: 0;
          margin-top: 15px;
          font-size: 14px;
          color: #212121;

          @media (min-width: 1024px) {
            margin: 0;
          }
        }

        .subtitle {
          margin: 0;
          margin-top: 5px;
          font-size: 13px;
          color: #717371;
        }
      }
    }
  }
`;

const ButtonWrapper = styled.div`
  padding: 50px 0;
  display: flex;
  justify-content: space-between;

  @media (min-width: 1024px) {
    padding: 100px 0;
  }
`;

const Line = styled.hr`
  display: block;
  margin: 0;
  border: 0.5px solid #dadce0;
  opacity: 0.5;
`;

const AboutClients = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    }
  }, []);

  return (
    <>
      <Seo
        title="Ayssa | Testimonios"
        description="40 años de experiencia respaldan nuestro prestigio"
        keywords="Aislantes Ayssa, Aislantes y Servicios, Aislamientos industriales, Aislamientos térmicos, Aislamientos térmicos industriales, Aislamientos térmicos industriales Guadalajara, Aislantes industriales en Guadalajara, Aislamientos para construcción, Aislantes y Servicios Ayssa, Refrigeración industrial, Tuberías industriales"
        url="https://aislantesyservicios.com/testimonies"
      />
      {isMobile ? (
        <NavBarMobile subMenuTitle="about" />
      ) : (
        <Navbar subMenuTitle="about" />
      )}
      <Wrapper id="header">
        <Background>
          <Fade>
            <h1 className="text">Grandes marcas están con nosotros</h1>
          </Fade>
        </Background>
        <Brands />
        <Line />
        <Container id="content">
          <Card>
            {CUSTOMERS.map((values, index) => (
              <CardContent
                key={index}
                image={values.background}
                isLeft={values.isLeft}
              >
                <div className="box">
                  <p className="text">{values.title}</p>
                  <div className="company">
                    <img src={values.img} className="logo" alt="logo" />
                    <div className="texts">
                      <p className="name">{values.name}</p>
                      <p className="subtitle">{values.subtitle}</p>
                    </div>
                  </div>
                </div>
              </CardContent>
            ))}
          </Card>
          <ButtonWrapper>
            <ButtonContainer>
              <Link to="/company" className="button-container">
                <ArrowForward rotate="180" />
                <button>NOSOTROS</button>
              </Link>
            </ButtonContainer>
            <ButtonContainer>
              <Link to="/industry" className="button-container">
                <button>INDUSTRIAS</button>
                <ArrowForward />
              </Link>
            </ButtonContainer>
          </ButtonWrapper>
        </Container>
      </Wrapper>
      <ContactCard />
      <Footer />
    </>
  );
};

export default AboutClients;
