import React from "react";
import styled from "styled-components";
import Fade from "react-reveal/Fade";

import { Card } from "./Card";

const QUALITY = [
  {
    img: "/home/MUNDIAL/CALIDAD.svg",
    title: "Calidad que da resultados",
    subtitle:
      "Nuestros materiales, productos y procesos con garantía de resultados.",
    action: "EMPRESA",
    link: "/company",
  },
  {
    img: "/home/MUNDIAL/EXPERIENCIA.svg",
    title: "Experiencia",
    subtitle: "40 Años en la industria; liderazgo para nuestros clientes.",
    action: "NUESTROS CLIENTES",
    link: "/testimonies",
  },
  {
    img: "/home/MUNDIAL/CERCANIA.svg",
    title: "Cercania",
    subtitle:
      "Estamos contigo, rescatamos proyectos y atendemos momentos de urgencia.",
    action: "INDUSTRIAS",
    link: "/industry",
  },
];

const Container = styled.div`
  background-color: #f8f9fa;
`;

const Wrapper = styled.div`
  padding: 4rem 1rem;

  @media (min-width: 1024px) {
    padding: 8rem 1rem;
  }
`;

const Title = styled.h2`
  color: #212121;
  font-size: 25px;
  width: 320px;
  text-align: center;
  margin: 0 auto;
  font-family: Gordita-Bold;

  @media (min-width: 1024px) {
    font-size: 50px;
    width: 611px;
  }
`;

const Text = styled.p`
  color: #717371;
  font-size: 14px;
  width: 320px;
  text-align: center;
  margin: 0 auto;
  padding-top: 15px;
  padding-bottom: 35px;
  font-family: Gordita-Regular;

  @media (min-width: 1024px) {
    padding-top: 20px;
    padding-bottom: 75px;
    font-size: 20px;
    width: 597px;
  }
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(1, auto);
  grid-gap: 0.5rem;
  justify-content: center;

  @media (min-width: 768px) {
    grid-gap: 1rem;
    grid-template-columns: repeat(3, auto);
  }
`;

const LandingQuality = () => (
  <Container>
    <Wrapper>
      <Fade bottom>
        <Title>Somos una empresa de confianza y clase mundial</Title>
      </Fade>
      <Text>
        Al contar con 40 años de experiencia en la industria podemos ofrecerte resultados de clase mundial.
      </Text>
      <Cards>
        {QUALITY.map((item, index) => (
          <Card
            key={index}
            index={index}
            {...item}
          />
        ))}
      </Cards>
    </Wrapper>
  </Container>
);

export default LandingQuality;
