import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { ArrowForward } from "Icons/ArrowForward";

const Wrapper = styled.button`
  max-width: 220px;
  height: 36px;
  border: none;
  outline: none;
  border-radius: 20px;
  background-color: ${({ isOpposite }) => isOpposite ? "white" : "#377DFF"};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
  cursor: pointer;

  @media (min-width: 768px) {
    height: 42px;
  }

  span {
    color: ${({ isOpposite }) => isOpposite ? "#377DFF" : "white"};
    font-size: 14px;
    margin-top: 2px;
    margin-right: 24px;
  }
`;

export const NextButton = ({ text, isOpposite, toRoute }) => (
  <Link
    to={toRoute}
    id="next-button"
  >
    <Wrapper isOpposite={isOpposite}>
      <span>
        {text}
      </span>
      <ArrowForward color={isOpposite ? "#377DFF" : "white"} />
    </Wrapper>
  </Link>
);
