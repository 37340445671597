import React, { useState } from "react";
import styled, { css } from "styled-components";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import { List } from "./List";

const Wrapper = styled.div`
  max-width: 425px;
  width: 100%;
  margin: 0 auto;
  padding: 50px 20px;
  background-color: white;

  @media (min-width: 1280px) {
    max-width: initial;
    padding: 150px 0;
  }

  .title {
    @media (min-width: 1280px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    h2 {
      font-family: Gordita-Bold;
      font-size: 25px;
      color: #212121;
      max-width: 651px;
      width: 100%;
      margin: 0;
      margin-bottom: 15px;

      @media (min-width: 1280px) {
        font-size: 50px;
      }
    }

    .subtitle {
      color: #717371;
      font-size: 14px;
      max-width: 330px;
      width: 100%;
      margin: 0;
      font-family: Gordita-Regular;

      @media (min-width: 1280px) {
        font-size: 20px;
        margin-left: 105px;
      }
    }
  }

  .card {
    padding-top: 35px;

    @media (min-width: 1280px) {
      padding-top: 100px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
    }

    .content {
      max-width: 582px;
      width: 100%;

      @media (min-width: 1280px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 48px;
      }

      .card-title {
        font-family: Gordita-Bold;
        font-size: 25px;
        color: #212121;
        margin-top: 35px;
        margin-bottom: 15px;
        width: 316px;

        @media (min-width: 1280px) {
          margin: 0;
          margin-bottom: 30px;
          font-size: 40px;
          width: auto;
        }
      }

      .card-description {
        color: #717371;
        font-size: 14px;
        margin: 0;
        font-family: Gordita-Regular;

        @media (min-width: 1280px) {
          font-size: 18px;
          line-height: 1.5;
        }
      }

      .card-buttons {
        overflow-y: scroll;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(4, auto);

        @media (min-width: 1280px) {
          &::-webkit-scrollbar {
            display: none;
          }
        }

        .card-button {
          width: 124px;
          height: 42px;
          border: none;
          border-radius: 20px;
          background-color: #fef5f5;
          color: #212121;
          margin-top: 50px;
          font-size: 14px;
          cursor: pointer;

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }

        .active {
          background-color: #ee3340;
          color: white;
        }
      }
    }
  }
`;

const Image = styled.div`
  ${({ image }) => css`
    background-image: url(${image});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 458px;
    width: 100%;
    height: 338px;
    border-radius: 10px;

    @media (min-width: 1280px) {
      height: 521px;
    }
  `}
`;

const Date = () => {
  const [date, setDate] = useState("1980");
  const item = List[date];

  function handleDate(value) {
    setDate(value);
  }

  return (
    <Wrapper>
      <div className="title">
        <h2>40 años en la industria son gracias a nuestra calidad</h2>
        <p className="subtitle">
          Conoce por qué somos los mejores en la industria.
        </p>
      </div>
      <div className="card">
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={item.image}
            addEndListener={(node, done) =>
              node.addEventListener("transitionend", done, false)
            }
            classNames="fade"
          >
            <Image image={item.image} />
          </CSSTransition>
        </SwitchTransition>
        <div className="content">
          <SwitchTransition mode="out-in">
            <CSSTransition
              key={item.title}
              addEndListener={(node, done) =>
                node.addEventListener("transitionend", done, false)
              }
              classNames="fade"
            >
              <p className="card-title">
                {item.title.split("\n").map((value, index) => (
                  <span key={index}>
                    {value}
                    <br />
                  </span>
                ))}
              </p>
            </CSSTransition>
          </SwitchTransition>
          <SwitchTransition mode="out-in">
            <CSSTransition
              key={item.description}
              addEndListener={(node, done) =>
                node.addEventListener("transitionend", done, false)
              }
              classNames="fade"
            >
              <p className="card-description">{item.description}</p>
            </CSSTransition>
          </SwitchTransition>
          <div className="card-buttons">
            <button
              className={`card-button ${date === "1980" ? "active" : ""}`}
              onClick={() => handleDate("1980")}
            >
              Año 1980
            </button>
            <button
              className={`card-button ${date === "2000" ? "active" : ""}`}
              onClick={() => handleDate("2000")}
            >
              Año 2000
            </button>
            <button
              className={`card-button ${date === "2010" ? "active" : ""}`}
              onClick={() => handleDate("2010")}
            >
              Año 2010
            </button>
            <button
              className={`card-button ${date === "2020" ? "active" : ""}`}
              onClick={() => handleDate("2020")}
            >
              Año 2020
            </button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Date;
