import React, { useState } from 'react';
import styled from 'styled-components';
import { Arrow } from 'Icons/Arrow';
import { IndustryMenu } from './IndustryMenu';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 63px;
  padding: 0 20px;

  @media (min-width: 768px) {
    padding: 0 80px;
  }
`;

const Text = styled.p`
  font-size: 14px;
  color: #212121;

  @media (min-width: 768px) {
    font-size: 20px;
  }
`;

const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ArrowText = styled.p`
  font-size: 14px;
  color: #212121;
  margin-right: 1rem;
`;

export const CasesMenu = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  function handleMenuOpen() {
    setMenuOpen(!isMenuOpen);
  }

  return (
    <>
      <Wrapper>
        <Text>Proyectos</Text>
        <ArrowWrapper onClick={handleMenuOpen}>
          <ArrowText>INDUSTRIAS</ArrowText>
          <Arrow
            color="#212121"
            rotate={isMenuOpen ? '270' : '90'}
            width="20px"
          />
        </ArrowWrapper>
      </Wrapper>
      <IndustryMenu isOpen={isMenuOpen} onMenuOpen={handleMenuOpen} />
    </>
  );
};
